import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import differenceInDays from 'date-fns/differenceInDays'
import parseISO from 'date-fns/parseISO'
import {
  CSSTransition,
  TransitionGroup
} from 'react-transition-group'
import { Loader } from '../loader/'
import { ErrorPage } from '../error-page'
import { HAULER_NEW_REQUESTS } from './graphql'
import { NewRequestsLineItem } from './line-item'
import './new-requests.scss'

export function NewRequests ({ haulerID, setPageRefresh }) {
  const [isRefreshing, setIsRefreshing] = useState(false)
  const {
    data: { haulerNewCaseRequests } = {}, loading, error, refetch: requestRefetch
  } = useQuery(HAULER_NEW_REQUESTS, {
    variables: {
      haulerID
    },
    fetchPolicy: 'no-cache'
  })

  function refetchNewRequest () {
    return requestRefetch()
      .catch(() => console.error('Error refetching new requests'))
      .finally(() => setIsRefreshing(false))
  }

  function renderApp () {
    let haulerRequests = []
    if (loading) {
      return (
        <div className='new-requests__loader'>
          <Loader isFetching={true}/>
        </div>
      )
    }

    if (error) {
      return (
        <ErrorPage>
          <p className='new-requests__error-text'>
            Sorry, there was an error retrieving your new requests.
          </p>
        </ErrorPage>
      )
    }

    if (!haulerNewCaseRequests.length) {
      return (
        <p className='new-requests__no-jobs'>No new requests.</p>
      )
    }

    const { haulerNewRequests, pendingRequests } = haulerNewCaseRequests.reduce((acc, newRequest) => {
      const product = newRequest.products[0]
      if (product.haulerActions.indexOf('CONFIRM_DELIVERY') === -1 && product.haulerActions.indexOf('CONFIRM_PICKUP') === -1) {
        acc.pendingRequests.push(newRequest)
      } else {
        acc.haulerNewRequests.push(newRequest)
      }

      return acc
    }, { haulerNewRequests: [], pendingRequests: [] })

    /* filter out any pending requests greater than 3 days old */
    const filteredPending = pendingRequests.filter(req => {
      const dateToUse = req.products[0].serviceType === 'PICK_UP' ? req.products[0].pickupDate : req.products[0].deliveryDate
      return differenceInDays(Date.now(), parseISO(dateToUse)) <= 3
    })

    haulerRequests = haulerNewRequests.concat(filteredPending)

    return (
      <TransitionGroup>
        {haulerRequests.map((request, index) => {
          return (
            <CSSTransition
              key={request.ID}
              timeout={800}
              classNames={{
                exit: 'new-requests__item-exit',
                exitActive: 'new-requests__item-exit-active'
              }}
            >
              <NewRequestsLineItem
                request = {request}
                updateRequests = {refetchNewRequest}
                updatePageRefreshEvent = {setPageRefresh}
                index = {index}
                key = {`request-${index}`}
              />
            </CSSTransition>
          )
        })}
      </TransitionGroup>
    )
  }

  return (
    <div className='new-requests new-requests__wrapper'>
      <h2 className='new-requests__header'>
        New Requests
        <button
          disabled={loading}
          aria-label='Refresh New Requests'
          className='new-requests__refresh-icon'
          onClick={() => {
            setIsRefreshing(true)
            refetchNewRequest()
          }}
        >
          {
            isRefreshing
              ? <Loader isFetching={true} className='new-requests__refresh-loader'/>
              : <FontAwesomeIcon
                icon={'redo'}
                className=''
              />
          }
        </button>
      </h2>
      <div className='new-requests__section'>
        {renderApp()}
      </div>
    </div>
  )
}

NewRequests.propTypes = {
  setPageRefresh: PropTypes.func.isRequired
}
