import PropTypes from 'prop-types'
import { dayjs } from '../../utils/dayjs'
import { GRAPHQL_AVAILABILITY_DELIVERY_TYPE } from './constants'

/**
 * Unfortunately there is not great way to parse times without dates in JS.
 * We only care about the time here so use today as the date and give us back the time.
 *
 * @param time
 * @returns {string}
 */
export function formatTime (time) {
  if (time === '00:00:00Z') {
    return '-:--'
  }
  const today = dayjs()
  const formattedTime = dayjs.utc(`${today.get('y')}-${today.get('M')}-${today.get('D')} ${time}`, 'YYYY-M-D HH:mm:ss').format('h:mm a')
  return formattedTime
}

export function determineNextAvailableService (availability) {
  if (!availability.mustCall) {
    if (availability.nextAvailableService) {
      return dayjs(availability.nextAvailableService).toDate()
    }

    if (
      availability.daysOut === null &&
      availability.sameDayActive === false &&
      availability.nextDayActive === false
    ) {
      return dayjs(availability.deliveryDate).toDate()
    }
  }

  return null
}

export const currentAvailabilityProps = {
  id: PropTypes.string.isRequired,
  deliveryType: PropTypes.oneOf(Object.values(GRAPHQL_AVAILABILITY_DELIVERY_TYPE)).isRequired,
  sameDayActive: PropTypes.bool.isRequired,
  sameDayCutoffTime: PropTypes.string.isRequired,
  nextDayActive: PropTypes.bool.isRequired,
  nextDayCutoffTime: PropTypes.string.isRequired,
  saturdayActive: PropTypes.bool.isRequired,
  saturdayCutoffTime: PropTypes.string.isRequired,
  sundayActive: PropTypes.bool.isRequired,
  sundayCutoffTime: PropTypes.string.isRequired,
  nextAvailableService: PropTypes.string,
  deliveryDate: PropTypes.string.isRequired,
  daysOut: PropTypes.number,
  daysOutCutoffTime: PropTypes.string.isRequired
}

export const sizedAvailabilityProps = {
  ...currentAvailabilityProps,
  sizeID: PropTypes.string.isRequired,
  size: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
}
