import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import jwtDecode from 'jwt-decode'
import Cookies from 'js-cookie'
import AppLayout from '../../layouts/app-layout'
import { ErrorPage } from '../error-page'
import { DISPATCHER_LITE_CLIENT_ID, LOGIN_URL } from '../../utils/constants'
import { logoutUser } from '../../utils'
import './route.scss'

/**
 * Route wraps Reach Router and provides functionality to hook into the routing lifecycle like when the path changes. This is useful for things
 * like analytics and authentication when a route change occurs.
 *
 * It's important to know we check the ID token to see if they are logged in or not, this token is meant to be a client token and in no way
 * gives a user access to our APIs to retrieve data. It is simply a convenience to the frontend to prevent an HTTP call.
 *
 * @param props - {@link RouteProps}
 */
export const Route = function Route (props) {
  const {
    path,
    component: RouteComponent,
    pageName,
    requiresAuth = true,
    layout: LayoutComponent = AppLayout
  } = props

  useEffect(function onRouteChange () {
    // got this from here https://www.analyticsmania.com/post/single-page-web-app-with-google-tag-manager/
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'Pageview',
      pagePath: path
    })
  }, [path])

  const rawCookieIDToken = Cookies.get('budgetdumpster_auth_id_token')

  if (!requiresAuth) {
    return (
      <LayoutComponent {...props} pageName={pageName} token={rawCookieIDToken}>
        <RouteComponent {...props}/>
      </LayoutComponent>
    )
  }

  let permissions = []
  let roles = []
  if (!RouteComponent || !pageName) {
    console.error('Invalid route props.')
    return null
  }

  if (!rawCookieIDToken) {
    console.warn('No ID token was found.')
    redirectToLogin()
    return null
  }
  const decodedCookieIDToken = jwtDecode(rawCookieIDToken)
  const hasOwnProperty = Object.prototype.hasOwnProperty

  if (!isCookieIDToken(decodedCookieIDToken)) {
    console.warn('ID token is missing standard claims we expect')
    redirectToLogin()
    return null
  }

  if (hasOwnProperty.call(decodedCookieIDToken, 'https://budgetdumpster.com/auth') && hasOwnProperty.call(decodedCookieIDToken['https://budgetdumpster.com/auth'], 'roles')) {
    roles = decodedCookieIDToken['https://budgetdumpster.com/auth'].roles
  }

  if (hasOwnProperty.call(decodedCookieIDToken, 'https://budgetdumpster.com/auth') && hasOwnProperty.call(decodedCookieIDToken['https://budgetdumpster.com/auth'], 'permissions')) {
    permissions = decodedCookieIDToken['https://budgetdumpster.com/auth'].permissions
  }

  const IDToken = {
    givenName: decodedCookieIDToken.given_name,
    familyName: decodedCookieIDToken.family_name,
    name: decodedCookieIDToken.name,
    picture: decodedCookieIDToken.picture,
    email: decodedCookieIDToken.email,
    emailVerified: decodedCookieIDToken.email_verified,
    sub: decodedCookieIDToken.sub,
    aud: decodedCookieIDToken.aud,
    exp: decodedCookieIDToken.exp,
    userMetadata: decodedCookieIDToken['https://budgetdumpster.com/user'],
    permissions: permissions || [],
    roles: roles || []
  }

  if (path.toLowerCase().includes('/admin/') && !roles.includes('DL Admin')) {
    return (
      <ErrorPage>
        <p className='error-page__error-text'>
          You do not have permission to use this page. <button type='button' onClick={logoutUser} className='button--action route__error-btn'>Logout</button> and try again.
        </p>
      </ErrorPage>
    )
  }

  if (IDToken.exp * 1000 < Date.now()) {
    console.warn('ID token is expired.')
    redirectToLogin()
    return null
  }

  if (IDToken.aud !== DISPATCHER_LITE_CLIENT_ID) {
    console.warn('User has the wrong audience, audience: ', IDToken.aud)
    redirectToLogin()
    return null
  }

  window.gtag('config', 'G-KYV5GT6450', {
    user_id: decodedCookieIDToken.sub
  })

  window.gtag('event', 'User_Pageview',
    {
      User_ID: decodedCookieIDToken.sub,
      Timestamp: new Date().toISOString(),
      Page_URL: props.path
    })

  return (
    <LayoutComponent {...props} pageName={pageName} IDToken={IDToken}>
      <RouteComponent {...props} IDToken={IDToken} />
    </LayoutComponent>
  )
}

function redirectToLogin () {
  window.location.href = LOGIN_URL
}

/**
 * Type guard to assert that an ID token from the cookies has all the properties we expect it to have.
 *
 * @param potentialCookieIDToken
 */
function isCookieIDToken (potentialCookieIDToken) {
  const IDTokenKeys = ['name', 'picture', 'email', 'email_verified', 'sub', 'exp', 'aud']
  return IDTokenKeys.every(key => potentialCookieIDToken[key] !== undefined)
}

Route.propTypes = {
  component: PropTypes.elementType.isRequired,
  layoutComponent: PropTypes.elementType
}
