import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CSSTransition } from 'react-transition-group'
import './alert.scss'
import { SUPPORT_CONTACTS } from '../../utils/constants'

export function Alert ({
  alertType,
  isOpen = false,
  setIsOpen,
  onCloseEvent,
  errorMessage = ''
}) {
  function handleAlertClose () {
    setIsOpen(false)
    if (onCloseEvent) {
      onCloseEvent()
    }
  }

  function checkAlertType () {
    return (
      <div className='alert_error__content'>
        <FontAwesomeIcon
          icon='exclamation-circle'
          className='alert_error__icon'
        />
        <span className='alert_error__text'>
          {
            errorMessage === ''
              ? <React.Fragment>
            An error occured. Please try again or email <a href={`mailto:${SUPPORT_CONTACTS.email}`}>{SUPPORT_CONTACTS.email}</a>
              </React.Fragment>
              : <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
          }
        </span>
      </div>
    )
  }

  return isOpen ? (
    <div className={`alert alert_${alertType}`}>
      <CSSTransition
        in = {isOpen}
        timeout = {800}
        classNames={{
          enter: 'alert__item-enter',
          enterActive: 'alert__item-enter-active',
          exit: 'alert__item-exit',
          exitActive: 'alert__item-exit-active'
        }}>
        <div className='alert__content'>
          {checkAlertType()}
          <FontAwesomeIcon
            icon={['far', 'times']}
            className='alert_close'
            onClick={handleAlertClose}
          />
        </div>
      </CSSTransition>
    </div>
  ) : null
}

Alert.propTypes = {
  alertType: PropTypes.string,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  onCloseEvent: PropTypes.func,
  errorMessage: PropTypes.string
}
