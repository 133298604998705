import React from 'react'
import PropTypes from 'prop-types'

/**
 * Image helps provide consistency by forcing images to use the CDN.
 *
 * @param src - Image src.
 * @param alt - Image alt.
 * @param className - Classes to be applied to the image.
 */
const Image = function Image ({ src, alt, className }) {
  return (
    <img className={className} src={cdn(src)} alt={alt}/>
  )

  function cdn (src) {
    // Clearly knows the full path of the image (potentially external image) and we can leave it untouched.
    if (src.startsWith('https')) {
      return src
    }

    return src.startsWith('/') ? `https://www.dispatcher.com/images${src}` : `https://www.dispatcher.com/images/${src}`
  }
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default Image
