import React from 'react'

export function ScheduledJobsTableHeader () {
  return (
    <thead>
      <tr>
        <th></th>
        <th>
            Address
        </th>
        <th>
            Order Type
        </th>
        <th>
            Product
        </th>
        {/* <th className='text-center'>
            Time Request
        </th>
        <th className='text-center'>
            ETA
        </th> */}
        <th>
            Action
        </th>
      </tr>
    </thead>
  )
}
