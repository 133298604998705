import React from 'react'
import PropTypes from 'prop-types'
import { Loader } from '../loader'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert } from '../alert/'
import { useMutation } from '@apollo/client'
import {
  CONFIRM_SERVICE
} from './graphql'

export function NewRequestsButtons ({
  isDetails,
  showDeclineReason,
  declineConfirmation,
  serviceType,
  serviceID,
  setDeclineConfirmation,
  requestConfirmed,
  declineForm,
  newRequestError,
  confirmRequest,
  errorMessage,
  setError,
  checkBeforeToday,
  requestStatus,
  setErrorMessage
}) {
  const [
    confirmService, { loading: confirmServiceLoading }
  ] = useMutation(CONFIRM_SERVICE)

  function handleConfirm () {
    confirmService({
      variables: {
        input: {
          order: serviceID,
          orderType: serviceType
        }
      }
    })
      .then(() => {
        confirmRequest(isDetails)
      })
      .catch(err => {
        let errorText = ''
        if (err.message.includes('Workflow Error')) {
          errorText = `This service has a pending change and cannot be confirmed. Please <a href="${window.location}">refresh</a> the page for the latest status.`
        }
        setErrorMessage(errorText)
        setError(true)
      })
  }

  function showPendingStatus () {
    if (showDeclineReason && requestStatus === 'PENDING_ISSUE') {
      return (
        <React.Fragment>
          <button disabled={true} className={classNames('button__submit button__submit--primary_warning-light new-requests__service-flag', { 'text-base': isDetails })}>
            <FontAwesomeIcon
              icon={['fas', 'flag']}
            />
            Pending Service Change
          </button>
          <p className='new-requests__service-flag-text'>
            Please refer to email for details
          </p>
        </React.Fragment>
      )
    }

    return (
      <button disabled={true} className={classNames('button__submit button__submit--primary_white', { 'text-base': isDetails })}>
        Pending Resolution
      </button>
    )
  }

  return (
    <div className={classNames({ 'new-requests__confirm-btn-wrapper': isDetails })}>
      {(!declineConfirmation && !showDeclineReason && !requestConfirmed) &&
        <React.Fragment>
          <button
            className={classNames('button__submit button__submit--primary_white new-requests__cancel-btn', { 'new-requests__cancel-btn_details': isDetails })}
            onClick={() => setDeclineConfirmation(true)}>
            Unable to Confirm
          </button>
          {confirmServiceLoading
            ? <Loader isFetching={true} isInline={true} />
            : !checkBeforeToday && <button
              className={classNames('button__submit button__submit--primary_green new-requests__confirm-btn', { 'new-requests__confirm-btn_details': isDetails })}
              onClick={handleConfirm}>
              Confirm
            </button>
          }
        </React.Fragment>
      }
      {(declineConfirmation && !showDeclineReason) && declineForm}
      {showDeclineReason &&
        showPendingStatus()
      }
      {requestConfirmed &&
        <button disabled className={classNames('button__submit button__submit--primary_mint', { 'text-base': isDetails })}>
          <FontAwesomeIcon
            icon={'check'}
            className='new-requests__verify-checkmark'
          />
          <span>Confirmed</span>
        </button>
      }
      {isDetails &&
        <div className='new-requests__alert-wrapper'>
          <Alert
            alertType = 'error'
            errorMessage={errorMessage}
            isOpen = {newRequestError}
            setIsOpen = {setError}/>
        </div>
      }
    </div>
  )
}

NewRequestsButtons.propTypes = {
  isDetails: PropTypes.bool.isRequired,
  showDeclineReason: PropTypes.bool.isRequired,
  requestConfirmed: PropTypes.bool.isRequired,
  serviceType: PropTypes.string.isRequired,
  serviceID: PropTypes.string.isRequired,
  confirmRequest: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  checkBeforeToday: PropTypes.bool.isRequired
}
