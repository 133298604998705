import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CSSTransition } from 'react-transition-group'
import { MobileMenu } from './mobile-menu'
import { useExpand } from '../../hooks/useExpand'
import './mobile-menu.scss'

export function MobileMenuContainer ({
  userID,
  logout,
  showContact
}) {
  const parentRef = useRef()
  const { isOpened, toggle } = useExpand(parentRef)

  return (
    <div ref={parentRef} className='mobile-menu-container'>
      <FontAwesomeIcon
        icon={['far', 'bars']}
        className='mobile-menu-container__menu-icon'
        onClick={toggle}/>
      <CSSTransition
        in={isOpened}
        timeout={300}
        classNames='mobile-menu-container__menu-transition'
        unmountOnExit>
        <MobileMenu
          userID = {userID}
          toggleMenu = {toggle}
          logout = {logout}
          showContact = {showContact}/>
      </CSSTransition>
    </div>
  )
}

MobileMenuContainer.propTypes = {
  userID: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  showContact: PropTypes.func.isRequired
}
