import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { BREAKPOINTS } from '../utils/constants'
/*
 * This mostly exists to limit the number of event listeners the react-responsive library adds for media query changes.
 * The whole app should know if we are on a large screen or not, having components check this individually is probably
 * not the best idea. https://github.com/contra/react-responsive. This should be used sparingly and we should use pure
 * CSS when possible. Using this context makes the most sense when it would be a performance issue to have the mobile,
 * tablet, and desktop views all rendered but hidden with display: none, this usually happens when the mobile and desktop
 * views are nothing like each other.
 */
export const ResponsiveContext = createContext({})
ResponsiveContext.displayName = 'ResponsiveContext'
export function ResponsiveProvider ({ children }) {
  const isXLScreen = useMediaQuery({ query: BREAKPOINTS.DESKTOP_LARGE })
  const isLGScreen = useMediaQuery({ query: BREAKPOINTS.DESKTOP })
  const isMDScreen = useMediaQuery({ query: BREAKPOINTS.TABLET })
  const isSMScreen = useMediaQuery({ query: BREAKPOINTS.MOBILE })

  return (
    <ResponsiveContext.Provider value={{
      isXLScreen,
      isLGScreen,
      isMDScreen,
      isSMScreen
    }}>
      {children}
    </ResponsiveContext.Provider>
  )
}

ResponsiveProvider.propTypes = {
  children: PropTypes.node
}

ResponsiveProvider.defaultProps = {
  children: null
}
