import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_GATEWAY_URL}/graphql`,
  credentials: 'include'
})

export const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
})
