import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { pluck, countBy } from 'kyanite'
import isToday from 'date-fns/isToday'
import isBefore from 'date-fns/isBefore'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { ORDER_TYPES } from '../scheduled-jobs/constants'
import { NewRequestsButtons } from './buttons'
// import { ETARequestButtons } from './etaRequestButtons.component'
// import { DropdownSelect } from '../dropdownSelect/dropdownSelect.component'
import { NewRequestsDeclineForm } from './decline-form'
// import { ETA_PICKER_VALUES } from '../etaPicker/etaPicker.const'
import { Alert } from '../alert/'
import { JobDetailsModal } from '../job-details-modal/'

export function NewRequestsLineItem ({ request, updateRequests, updatePageRefreshEvent }) {
  const [requestProduct] = request.products
  const isPickup = requestProduct.serviceType === 'PICK_UP'
  const alreadyDeclined = !requestProduct.haulerActions.includes('CONFIRM_PICKUP') && !requestProduct.haulerActions.includes('CONFIRM_DELIVERY')
  const dateToUse = isPickup ? requestProduct.pickupDate : requestProduct.deliveryDate
  const etaRequest = requestProduct.serviceType === 'ETA'
  const [detailsOpen, setDetailsOpen] = useState(false)
  const [showDeclineReason, setShowDeclineReason] = useState(false)
  const [declineConfirmation, setDeclineConfirmation] = useState(false)
  const [declineReason, setDeclineReason] = useState('')
  const [declineDetails, setDeclineDetails] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  // const [etaTime, setETATime] = useState('')
  const [showError, setShowError] = useState(false)
  const [etaConfirmed, setETAConfirmed] = useState(false)
  const [requestConfirmed, setRequestConfirmed] = useState(false)
  const formattedDate = (dateToUse != null && dateToUse !== '') ? format(parseISO(dateToUse), 'MM/dd/yy') : ''
  const sizes = countBy(x => x.toString(), pluck('size', request.products))
  const formattedProducts = Object.keys(sizes).map((size, i) => {
    const product = sizes[size] > 1 ? `(${sizes[size]})` : ''
    return ` ${size} Yard${product}${(Object.keys(sizes).length > 1 && !Object.keys(sizes).length === i + 1) ? ',' : ''}`
  })

  useEffect(() => {
    setShowDeclineReason(alreadyDeclined)
  }, [requestProduct.haulerActions, alreadyDeclined])

  function isRequestToday (requestProduct) {
    return isToday(parseISO(requestProduct.deliveryDate)) || isToday(parseISO(requestProduct.pickupDate))
  }

  function isRequestBeforeToday (requestProduct) {
    const today = parseISO(format(new Date(), 'yyyy-MM-dd'))
    return isBefore(parseISO(requestProduct.deliveryDate), today) || isBefore(parseISO(requestProduct.pickupDate), today)
  }

  function confirmRequest () {
    etaRequest ? setETAConfirmed(true) : setRequestConfirmed(true)
    updatePageRefreshEvent()
    updateRequests()
  }

  function confirmRequestDetails () {
    if (requestConfirmed || etaConfirmed) {
      updatePageRefreshEvent()
      updateRequests()
    }
  }

  function checkRequestType (isDetails) {
    // if (etaRequest) {
    //   return (
    //     <ETARequestButtons
    //       isDetails = {isDetails}
    //       etaConfirmed = {etaConfirmed}
    //       etaTime = {etaTime}
    //       setETATime = {setETATime}
    //       confirmRequest = {confirmRequest}
    //     />
    //   )
    // }

    return (
      <NewRequestsButtons
        isDetails = {isDetails}
        showDeclineReason = {showDeclineReason}
        declineConfirmation = {declineConfirmation}
        setDeclineConfirmation = {setDeclineConfirmation}
        requestConfirmed = {requestConfirmed}
        confirmRequest = {confirmRequest}
        serviceType = {requestProduct.serviceType}
        serviceID = {request.ID}
        newRequestError = {showError}
        errorMessage = {errorMessage}
        setErrorMessage = {setErrorMessage}
        setError = {setShowError}
        checkBeforeToday = {isRequestBeforeToday(requestProduct) }
        requestStatus = {request.status}
        declineForm = {
          <NewRequestsDeclineForm
            setDeclineConfirmation = {setDeclineConfirmation}
            declineReason = {declineReason}
            setDeclineReason = {setDeclineReason}
            setShowDeclineReason = {setShowDeclineReason}
            isDetails = {isDetails}
            declineDetails = {declineDetails}
            setDeclineDetails = {setDeclineDetails}
            serviceType = {requestProduct.serviceType}
            serviceID = {request.ID}
            setError = {setShowError}
          />
        }
      />
    )
  }

  return (
    <div className='new-requests__line-item__wrapper'>
      {<JobDetailsModal
        isOpen = {detailsOpen}
        setIsOpen = {setDetailsOpen}
        title='New Request Details'
        service = {request}
        requestDate = {dateToUse}
        formattedProducts = {formattedProducts.join(', ')}
        buttonComponent = {checkRequestType(true)}
        etaComponent = { /*
          etaRequest
            ? <DropdownSelect
              selected = {etaTime}
              disabled = {etaConfirmed}
              selectOptions = {ETA_PICKER_VALUES}
              setSelected = {setETATime}
              className = {'w-full'}
            />
            : null */
          false}
        onCloseEvent = {confirmRequestDetails}
      />}
      <div className='new-requests__line-item'>
        <div className='new-requests__icon-wrapper'>
          <FontAwesomeIcon
            role='button'
            aria-label='New Requests Details Modal'
            icon = {'info-circle'}
            className = {classNames('new-requests__icon', { 'new-requests__icon_decline': showDeclineReason })}
            onClick={() => setDetailsOpen(true)}/>
        </div>
        <div>
          <div className={classNames('new-requests__request-type-header', { 'new-requests__request-type-header_decline': showDeclineReason })}>
            {etaRequest ? 'ETA Request' : `${ORDER_TYPES[requestProduct.serviceType]} - ${formattedProducts}`}
          </div>
          <p className={classNames('new-requests__date', {
            'new-requests__date_today': isRequestToday(requestProduct),
            'new-requests__date_decline': showDeclineReason,
            'new-requests__date_before-today': isRequestBeforeToday(requestProduct) && !showDeclineReason
          })}>
            {isRequestToday(requestProduct) ? `Today, ${formattedDate}` : formattedDate}
          </p>
          <p className={classNames('new-requests__address', { 'new-requests__address_decline': showDeclineReason })}>
            {requestProduct.address.street}, {requestProduct.address.city}, {requestProduct.address.state} {requestProduct.address.postalCode}
          </p>
          {checkRequestType(false)}
        </div>
      </div>
      <Alert
        alertType = 'error'
        errorMessage={errorMessage}
        isOpen = {showError}
        setIsOpen = {setShowError}/>
    </div>
  )
}

NewRequestsLineItem.propTypes = {
  updateRequests: PropTypes.func.isRequired,
  updatePageRefreshEvent: PropTypes.func.isRequired
}
