import React, { useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { SUPPORT_CONTACTS } from '../../utils/constants'
import './help.scss'

export function HelpContact () {
  const [copied, setCopied] = useState(false)
  const copyRef = useRef()

  function copyToClipboard () {
    if (copyRef && copyRef.current) {
      copyRef.current.select()
      document.execCommand('copy')
      setCopied(true)
    }
  }

  return (
    <div className='help-container__contact-container'>
      <div className='help-container__contact-container__title'>Need help or have feedback? Email Us.</div>
      <div className='help-container__contact-container__text'>
        Click below to copy our email address and use your normal email program to send us a message.
      </div>
      <label htmlFor='contact email'>
        <input
          name='contact email'
          ref={copyRef}
          readOnly
          className='help-container__contact-container__input'
          value={SUPPORT_CONTACTS.email.toLowerCase()}
          type='text'
        />
      </label>
      <button
        className={classNames('help-container__contact-container__copy-button button__submit--primary_green', {
          'help-container__contact-container__copy-button_copy': !copied,
          'help-container__contact-container__copy-button_copied': copied
        })}
        onClick={(e) => copyToClipboard(e)}>
        {copied ? (
          <React.Fragment>
            <span>COPIED</span>
            <FontAwesomeIcon
              icon={'check'}
              className='help-container__contact-container__copy-button_icon' />
          </React.Fragment>)
          : (<span>COPY TO CLIPBOARD</span>)}
      </button>
    </div>
  )
}
