import React, { useState } from 'react'
import { Link } from '@reach/router'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from '../image/'
import classNames from 'classnames'
import './site-header.scss'
import Modal from '../modal/'
import { HelpContainer } from '../help/'
import { HelpContact } from '../help/help-contact'
import { MobileMenuContainer } from '../mobile-menu'
import { MobileLogout } from '../mobile-menu/mobile-logout'
import { LOGIN_URL } from '../../utils/constants'
import { logoutUser } from '../../utils'

export const SiteHeader = function SiteHeader ({ isInternalUser, permissions, userID, isUserLoggedIn }) {
  const [showSettingDropdown, setShowSettingsDropdown] = useState(false)
  const [showMobileLogout, setShowMobileLogout] = useState(false)
  const [contactOpen, setContactOpen] = useState(false)

  function handleDropDown () {
    setShowSettingsDropdown(!showSettingDropdown)
  }

  return (
    <React.Fragment>
      {showMobileLogout && isUserLoggedIn &&
        <Modal
          isOpen = {showMobileLogout}
          setIsOpen = {setShowMobileLogout}
          showModalContent = {false}>
          <MobileLogout
            logout = {logoutUser}
            toggleLogoutModal = {setShowMobileLogout}
          />
        </Modal>
      }
      {contactOpen && isUserLoggedIn &&
        <Modal
          isOpen = {contactOpen}
          setIsOpen = {setContactOpen}>
          <HelpContact />
        </Modal>
      }
      <header className='header header_position_top'>
        <Link to='/'>
          <Image className='header__logo' src='Dispatcher-Lite-2-Color-Inverse.svg' alt='Dispatcher Lite logo'/>
        </Link>
        {isUserLoggedIn ? (
          <React.Fragment>
            <div className='header__mobile-menu'>
              <MobileMenuContainer
                showContact = {setContactOpen}
                logout = {setShowMobileLogout}
                userID = {userID}/>
            </div>
            <HelpContainer/>
            <div className='header__user-menu'>
              <button className='header__user-information' onClick={handleDropDown} aria-label='Menu'>
                {userID}
                <FontAwesomeIcon
                  icon={!showSettingDropdown ? 'chevron-down' : 'chevron-up'}
                  className='header__select-icon'/>
              </button>
              <ul
                className={classNames(
                  {
                    'header__user-menu_hidden': !showSettingDropdown
                  }
                )}
              >
                <li>
                  <button
                    className='header__user-menu-btn'
                    onClick={logoutUser}
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          </React.Fragment>
        ) : <a href={LOGIN_URL} rel="noopener noreferrer" className='header__login'>Login</a>}
      </header>
    </React.Fragment>
  )
}

SiteHeader.propTypes = {
  userID: PropTypes.string,
  permissions: PropTypes.arrayOf(PropTypes.any),
  isInternalUser: PropTypes.bool,
  isUserLoggedIn: PropTypes.bool
}
