export const DECLINE_MAP = {
  INVENTORY: 'Inventory',
  SCHEDULING: 'Scheduling',
  SERVICE_AREA: 'Service Area',
  MORE_INFO_REQUIRED: 'More Info Required',
  OTHER_ISSUE: 'Other'
}

export const DECLINE_VALUES = Object.entries(DECLINE_MAP).map(([key, value]) => ({
  value: key,
  label: value
}))

export const STATUSES = {
  NEW: 'New',
  NON_CONFIRMATION_DELIVERY_DATE: 'Non Confirmation Delivery Date',
  NON_CONFIRMATION_PAST_24_HOURS: 'Non Confirmation Past 24 Hours',
  NON_CONFIRMATION_PAST_12_HOURS: 'Non Confirmation Past 12 Hours',
  REOPENED: 'Reopened',
  PENDING_TASK: 'Pending Task',
  PENDING_URGENT: 'Pending Urgent',
  PENDING_HAULER_RESPONSE: 'Pending Hauler Response',
  PENDING_CONFIRMATION: 'Pending Confirmation'
}
