import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './show-hide-password.scss'
import classNames from 'classnames'

const ShowHidePassword = function ShowHidePassword ({
  wrapperClasses = '',
  inputClasses = '',
  inputName = 'password',
  onChangeHandler = function () {},
  isNewPassword = false
}) {
  const [showPassword, setShowPassword] = useState(false)

  function handleButtonClick (e) {
    setShowPassword(!showPassword)
  }
  return (
    <span className={classNames('show-hide-password', wrapperClasses)}>
      <input
        name={inputName}
        className={inputClasses}
        type={showPassword ? 'text' : 'password'}
        required={true}
        autoComplete={isNewPassword ? 'new-password' : 'current-password'}
        onChange={onChangeHandler}
      />
      <button
        className='show-hide-password__button'
        type='button'
        aria-label='Show/Hide Password'
      >
        <FontAwesomeIcon
          className='show-hide-password__button-icon'
          icon={showPassword ? 'eye-slash' : 'eye'}
          onClick={handleButtonClick}
        />
      </button>
    </span>
  )
}

ShowHidePassword.propTypes = {
  wrapperClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  inputName: PropTypes.string,
  onChangeHandler: PropTypes.func,
  isNewPassword: PropTypes.bool
}

export default ShowHidePassword
