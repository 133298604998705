import { gql } from '@apollo/client'
import { sharedAvailabilityProperties } from './fragments'

export const GET_NEW_DELIVERY_AVAILABILITY = gql`
  query NewDeliveryAvailability {
    haulerAvailabilities {
      newDeliveries {
        ${sharedAvailabilityProperties}
        sizeID
        size {
          name
          id
        }
      }
    }
  }
`

export const GET_EMPTY_RETURN_AVAILABILITY = gql`
  query EmptyReturnAvailability {
    haulerAvailabilities {
      emptyReturn {
        ${sharedAvailabilityProperties}
      }
    }
  }
`

export const GET_PICKUP_AVAILABILITY = gql`
  query PickupAvailability {
    haulerAvailabilities {
      pickUp {
        ${sharedAvailabilityProperties}
      }
    }
  }
`

export const GET_AGGREGATE_AVAILABILITY = gql`
  query AggregateAvailability {
    haulerAvailabilities {
      aggregate {
          sameDayActive
          nextDayActive
          saturdayActive
          sundayActive
          nextAvailableService
          daysOut
          mustCall
      }
    }
  }
`
export const GET_ALL_AVAILABILITY = gql`
  query Availability {
    haulerAvailabilities {
      newDeliveries {
        ${sharedAvailabilityProperties}
        sizeID
        size {
          name
          id
        }
      }
      emptyReturn {
        ${sharedAvailabilityProperties}
      }
      pickUp {
        ${sharedAvailabilityProperties}
      }
      aggregate {
          sameDayActive
          nextDayActive
          saturdayActive
          sundayActive
          nextAvailableService
          daysOut
          mustCall
      }
    }
  }
`

export const HAULER_SCHEDULED_SERVICES_BY_DATE = gql`
  query haulerScheduledServicesByDate($haulerID: ID!, $date: String) {
    haulerScheduledServicesByDate(haulerID: $haulerID, date: $date){
      status
      onSiteContact {
        phone
        fullName
      }
      orderNumber
      products {
        deliveryDate
        pickupDate
        serviceType
        caseProductID
        size
        debris
        productStatus
        deliveryInstructions,
        specialRequests,
        deliveryETATime
        pickupETATime
        address {
          street
          city
          state
          postalCode
        }
        haulerActions
        pageSection
    }
    }
  }
`
