import { PropTypes } from 'prop-types'

export const BREAKPOINTS = {
  MOBILE: '(min-width: 300px)',
  TABLET: '(min-width: 768px)',
  DESKTOP: '(min-width: 1024px)',
  DESKTOP_LARGE: '(min-width: 1400px)'
}

export const BREAKPOINT_VALUES = {
  IS_MOBILE: 'isMobile',
  IS_TABLET: 'isTablet',
  IS_DESKTOP: 'isDesktop',
  IS_DESKTOP_LARGE: 'isDesktopLarge'
}

export const CURRENCIES = {
  USD: 'USD'
}

export const identityTokenPropTypes = {
  name: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  emailVerified: PropTypes.bool.isRequired,
  sub: PropTypes.string.isRequired,
  aud: PropTypes.string.isRequired,
  exp: PropTypes.number.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.any).isRequired,
  userMetadata: PropTypes.shape({
    companyName: PropTypes.string
  }).isRequired
}

export const SUPPORT_CONTACTS = {
  phone: '866-557-9146',
  email: 'Support@cleanupcontractors.com'
}

export const LOGIN_URL = `https://auth.dispatcher.com/login?for=${encodeURIComponent('https://auth.budgetdumpster.com/dispatcher-lite')}&redirect_url=${encodeURIComponent('https://auth.dispatcher.com/register-token')}&app_url=${encodeURIComponent(window.location.href)}`
export const LOGIN_URL_TO_HOMEPAGE = `https://auth.dispatcher.com/login?for=${encodeURIComponent('https://auth.budgetdumpster.com/dispatcher-lite')}&redirect_url=${encodeURIComponent('https://auth.dispatcher.com/register-token')}&app_url=${encodeURIComponent('https://' + window.location.hostname)}`

// auth0 client IDs, the ID token uses these IDs as it's "aud" claim.
export const DISPATCHER_LITE_CLIENT_ID = 'YjHlBKtJtEISqJyA01Z6RxUeUyusT7Sr'
