import React, { useState, useContext } from 'react'
import { Availability } from '../../components/availability/'
import { ScheduledJobs } from '../../components/scheduled-jobs/'
import { NewRequests } from '../../components/new-requests/'
import { MobileNav } from '../../components/mobile-nav'
import { ResponsiveContext } from '../../context/responsive'
import './dashboard.scss'

export default function Dashboard ({ accounts }) {
  // TODO: bring in the hauler ids based on auth token
  const [sfAccount] = accounts
  const [newRequestsUpdated, setNewRequestsUpdated] = useState(0)
  const [navLink, setNavLink] = useState('newRequests')
  const { isMDScreen } = useContext(ResponsiveContext)
  function pageRefreshEvent () {
    setNewRequestsUpdated(newRequestsUpdated + 1)
  }

  function updateActiveNavLink (type) {
    setNavLink(type)
  }

  if (isMDScreen) {
    return (
      <div className="dashboard__wrapper">
        <div className="dashboard__left-column">
          <NewRequests haulerID={sfAccount} setPageRefresh={pageRefreshEvent} />
        </div>
        <div className="dashboard__right-column">
          <ScheduledJobs haulerID={sfAccount} halfHeight={true} refreshEvent={ newRequestsUpdated }/>
          <Availability />
        </div>
      </div>
    )
  }

  return (
    <div className="dashboard__wrapper">
      <MobileNav
        setActiveLink = {updateActiveNavLink}
        activeLink = {navLink}
        newRequestIndicator = {0}/>
      {navLink === 'newRequests' && <div className="dashboard__left-column">
        <NewRequests haulerID={sfAccount} setPageRefresh={pageRefreshEvent} />
      </div>}
      {navLink === 'scheduledJobs' && <div className="dashboard__left-column">
        <ScheduledJobs haulerID={sfAccount} halfHeight={true} refreshEvent={ newRequestsUpdated }/>
      </div>}
      {navLink === 'availability' && <div className="dashboard__left-column">
        <Availability />
      </div>}
    </div>
  )
}
