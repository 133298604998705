import React, { useState, Fragment, useRef } from 'react'
import { Link } from '@reach/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useExpand } from '../../hooks/useExpand'
import Modal from '../modal/'
import { HelpContact } from './help-contact'
import './help.scss'

export function HelpContainer () {
  const parentRef = useRef()
  const { isOpened, toggle } = useExpand(parentRef)
  const [contactOpen, setContactOpen] = useState(false)

  function showLink (path) {
    if (window.location.href.indexOf(path) > -1) {
      return false
    }
    return true
  }

  return (
    <Fragment>
      {contactOpen && <Modal
        isOpen = {contactOpen}
        setIsOpen = {setContactOpen}
        modalClassName = ''>
        <HelpContact />
      </Modal>}
      <div className='help-container__help' ref={parentRef}>
        <button
          className='help-container__help__button'
          onClick={toggle}>
          Help
        </button>
        {isOpened && <ul className='help-container'>
          <li className='help-container__item'>
            <div className='help-container__item__quickstart-link'>
              <a
                href='https://www.dispatcher.com/documents/dispatcher-lite-quick-start-guide1.0.pdf'
                target='blank'
                onClick={toggle}>
                <span className='help-container__item__quickstart-link__text'>Quick Start Guide</span>
                <FontAwesomeIcon
                  icon='external-link-alt'
                  className='help-container__item__quickstart-link__icon'
                />
              </a>
            </div>
          </li>
          <li className='help-container__item'>
            <div
              className='help-container__item__contact'
              role='button'
              onClick={() => {
                setContactOpen(!contactOpen)
                toggle()
              }}>
              Contact Us
            </div>
          </li>
          {showLink('tutorial') &&
            <li className='help-container__item'>
              <Link
                className='help-container__item__tutorial'
                to='/tutorial'>
                Video Tutorial
              </Link>
            </li>}
        </ul>}
      </div>
    </Fragment>
  )
}
