export const HAULER_ACTIONS = {
  confirmed: 'CONFIRMED',
  pending: 'PENDING_CONFIRMATION',
  cancelled: 'CANCELLED',
  serviceComplete: 'COMPLETE_SERVICE'
}

export const ORDER_TYPES = {
  NEW_DELIVERY: 'New Delivery',
  PICK_UP: 'Pick Up',
  EMPTY_AND_RETURN: 'Empty & Return',
  SWAP_OUT: 'Swap Out',
  OTHER: 'Other'
}
