import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from './../../../components/image'
import InviteUserForm from './../../../components/invite-user-form'
import './new-user-invitation.scss'

const NewUserInvitation = function NewUserInvitation () {
  const [emailAddresses, setEmailAddresses] = useState([''])
  const [pageStep, setPageStep] = useState(0)
  const [errorText, setErrorText] = useState('')

  if (pageStep === -1) {
    return (
      <section className='auth-like-wrapper new-user-invitation'>
        <header className='new-user-invitation__header'>
          <FontAwesomeIcon
            icon = {'check-circle'}
            className = 'new-user-invitation--icon new-user-invitation--icon_error' />
          <h1 className='new-user-invitation__header-text'>
            Error
          </h1>
          <p className='new-user-invitation__header-text new-user-invitation__header-text--secondary new-user-invitation__header-text--tiny_margin'>
            {errorText}
          </p>
        </header>
        <button
          className='new-user-invitation__submit-btn button__submit button__submit--primary_white'
          onClick={() => setPageStep(0)}
        >
          Try Again
        </button>
      </section>
    )
  }

  if (pageStep === 0) {
    return (
      <section className='auth-like-wrapper new-user-invitation'>
        <header className='new-user-invitation__header'>
          <Image className='new-user-invitation__logo' src='Dispatcher-Lite-Primary-Vertical.svg' alt='Dispatcher Lite logo'/>
          <h1 className='new-user-invitation__header-text'>
            Create a New User Account
          </h1>
          <p className='new-user-invitation__header-text new-user-invitation__header-text--secondary'>
            Creating an account will send the user(s) an email to set up their password and login.
          </p>
        </header>
        <InviteUserForm
          setPageStep={setPageStep}
          setParentErrorText={setErrorText}
          userEmails={emailAddresses}
          setUserEmails={setEmailAddresses}
        />
      </section>
    )
  }

  if (pageStep === 1) {
    return (
      <section className='auth-like-wrapper new-user-invitation'>
        <header className='new-user-invitation__header'>
          <FontAwesomeIcon
            icon = {'check-circle'}
            className = 'new-user-invitation--icon new-user-invitation--icon_success' />
          <h1 className='new-user-invitation__header-text'>
            Success
          </h1>
          <p className='new-user-invitation__header-text new-user-invitation__header-text--secondary new-user-invitation__header-text--tiny_margin'>
            Account has been created and the user(s) have been sent an email.
          </p>
        </header>
        <button
          className='new-user-invitation__submit-btn button__submit button__submit--primary_white'
          onClick={() => {
            setEmailAddresses([''])
            setPageStep(0)
          }}
        >
          Create Another Account
        </button>
      </section>
    )
  }
}

export default NewUserInvitation
