import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Alert } from '../alert/'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation } from '@apollo/client'
import { MARK_SERVICE_AS_COMPLETE } from '../../graphql/mutations'
export function ScheduledJobsDetailsButtons ({
  completionStep,
  canAction,
  caseID
}) {
  const [detailsCompletionStep, setDetailsCompletionStep] = useState(completionStep)
  const [markServiceAsComplete] = useMutation(MARK_SERVICE_AS_COMPLETE)
  const [showError, setShowError] = useState(false)

  function setAsComplete () {
    if (detailsCompletionStep === 2) {
      return
    }

    if (detailsCompletionStep === 1) {
      return markServiceAsComplete({ variables: { service: caseID } })
        .then(() => {
          setDetailsCompletionStep(2)
        })
        .catch((err) => {
          console.error('error marking service as complete', err)
          setShowError(true)
        })
    }

    return setDetailsCompletionStep(detailsCompletionStep + 1)
  }

  function cancelComplete () {
    setDetailsCompletionStep(0)
  }

  function updateCompletionStep () {
    if (detailsCompletionStep === 0) {
      return (
        <div className='scheduled-jobs__details-button-container scheduled-jobs__details-button-container__no-message'>
          <button
            onClick={setAsComplete}
            className='button__submit button__submit--primary_green'>
            Mark Complete
          </button>
        </div>
      )
    }

    if (detailsCompletionStep === 1) {
      return (
        <div className={classNames('scheduled-jobs__details-button-container__verify-details', {
          'scheduled-jobs__details-button-container__verify-details_error': showError
        })}>
          {!showError &&
            <React.Fragment>
              <div className='scheduled-jobs__details-button-container__verify-message'>Please click again to verify this job is complete</div>
              <div className='scheduled-jobs__details-button-container__verify-details__button-container'>
                <button
                  onClick={setAsComplete}
                  className='button__submit button__submit--primary_green scheduled-jobs__details-button-container__verify-details__button-container__verify-buttons_margin-right'>
                  Verify Completion
                </button>
                <button
                  onClick={() => cancelComplete()}
                  className='button__submit button__submit--primary_white'>
                  Cancel
                </button>
              </div>
            </React.Fragment>
          }
          <Alert
            alertType = 'error'
            isOpen = {showError}
            setIsOpen = {setShowError}/>
        </div>
      )
    }

    if (detailsCompletionStep === 2) {
      return (
        <div className='scheduled-jobs__details-button-container scheduled-jobs__details-button-container__no-message'>
          <button disabled className='button__submit button__submit--primary_mint'>
            <FontAwesomeIcon
              icon={'check'}
              className='scheduled-jobs__verify-checkmark'/>
            <span>Completed</span>
          </button>
        </div>
      )
    }
  }

  return (
    <React.Fragment>
      { canAction
        ? (
          updateCompletionStep()
        )
        : (
          <div className='scheduled-jobs__details-button-container scheduled-jobs__details-button-container__no-message'>
            Cancelled
          </div>
        )
      }
    </React.Fragment>
  )
}

ScheduledJobsDetailsButtons.propTypes = {
  completionStep: PropTypes.number.isRequired,
  canAction: PropTypes.bool.isRequired
}
