import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cookies from 'js-cookie'
import { Link } from '@reach/router'
import './tutorial.scss'

export default function Tutorial () {
  const showBackLink = Cookies.get('budgetdumpster_auth_id_token')
  return (
    <div className="tutorial__wrapper">
      <div className="tutorial__header-wrapper">
        {showBackLink?.length && (
          <Link to='/' className='tutorial__back-link'>
            <FontAwesomeIcon
              icon={['far', 'arrow-left']}
            />
          </Link>
        )}
        <h1 className="tutorial__header">
          Tutorial Video
        </h1>
      </div>
      <p className="tutorial__description">
        Dispatcher LITE is an all-in-one tool for managing your business with Cleanup Contractors.
        Watch the video below to see how you can receive and confirm new service requests,
        review what services you have on schedule and make easy updates to your availability all in one simple page.
      </p>
      <div className="tutorial__video">
        <div className="wistia_responsive_wrapper">
          <div className="wistia_embed wistia_async_k8c0bkdf9y seo=false videoFoam=true"
            style={{ height: '100%', position: 'relative', width: '100%' }} >
            <div className="wistia_swatch"
              style={{ height: '100%', left: 0, opacity: 0, overflow: 'hidden', position: 'absolute', top: 0, transition: 'opacity 200ms', width: '100%' }}>
              <img src="https://fast.wistia.com/embed/medias/k8c0bkdf9y/swatch"
                style={{ filter: 'blur(5px)', height: '100%', objectFit: 'contain', width: '100%' }} alt="Dispatcher Lite Tutorial"
                aria-hidden="true"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
