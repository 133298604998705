import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import addDays from 'date-fns/addDays'
import subDays from 'date-fns/subDays'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import isToday from 'date-fns/isToday'
import isTomorrow from 'date-fns/isTomorrow'
import isYesterday from 'date-fns/isYesterday'

export function ScheduledJobsDate ({ date, setDate, isLoading }) {
  function updateDate (update) {
    if (update === 'add') {
      setDate(format(addDays(parseISO(date), 1), 'y-MM-dd'))
    } else {
      setDate(format(subDays(parseISO(date), 1), 'y-MM-dd'))
    }
  }

  function formatDate () {
    const formattedDate = format(parseISO(date), 'MM/dd/yy')

    if (isToday(parseISO(date))) {
      return `Today, ${formattedDate}`
    } else if (isTomorrow(parseISO(date))) {
      return `Tomorrow, ${formattedDate}`
    } else if (isYesterday(parseISO(date))) {
      return `Yesterday, ${formattedDate}`
    }

    return format(parseISO(date), 'MM/dd/yy')
  }

  return (
    <p className='scheduled-jobs__date-picker'>
      <button aria-label='Previous Day' disabled={isLoading} onClick={() => updateDate('subtract')}>
        <FontAwesomeIcon
          icon='chevron-left'
          className='cursor-pointer'
        />
      </button>
      <span>{formatDate()}</span>
      <button aria-label='Next Day' disabled={isLoading} onClick={() => updateDate('add')}>
        <FontAwesomeIcon
          icon='chevron-right'
          className='cursor-pointer'
        />
      </button>
    </p>
  )
}

ScheduledJobsDate.propTypes = {
  date: PropTypes.string.isRequired,
  setDate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}
