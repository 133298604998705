import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'
import './mobile-menu.scss'

export function MobileMenu ({
  userID,
  toggleMenu,
  logout,
  showContact
}) {
  function showLink (path) {
    if (window.location.href.indexOf(path) > -1) {
      return false
    }
    return true
  }
  return (
    <div className='mobile-menu-container__menu'>
      <div className='mobile-menu-container__menu__user'>{userID}</div>
      <ul className='mobile-menu-container__menu__list'>
        <li onClick={toggleMenu}>
          <a
            className='mobile-menu-container__menu__list__item_link'
            href='https://www.dispatcher.com/documents/dispatcher-lite-quick-start-guide1.0.pdf'
            target='blank'>
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              className='mobile-menu-container__menu__list__item_icon'/>
            <span>Quick Start Guide</span>
          </a>
        </li>
        <li
          className='mobile-menu-container__menu__list__item'
          onClick={() => {
            showContact(true)
            toggleMenu()
          }}>
          <FontAwesomeIcon
            icon={['far', 'envelope']}
            className='mobile-menu-container__menu__list__item_icon'/>
          <span>Contact Us</span>
        </li>
        {showLink('tutorial') &&
          <li onClick={() => toggleMenu}>
            <Link
              className='mobile-menu-container__menu__list__item_link'
              to='/tutorial'>
              <FontAwesomeIcon
                icon={['far', 'video']}
                className='mobile-menu-container__menu__list__item_icon'/>
              <span>Video Tutorial</span>
            </Link>
          </li>}
        <li
          className='mobile-menu-container__menu__list__item'
          onClick={() => {
            logout(true)
            toggleMenu()
          }}>
          <FontAwesomeIcon
            icon={['fas', 'sign-out']}
            className='mobile-menu-container__menu__list__item_icon'/>
          <span>Log Out</span>
        </li>
      </ul>
    </div>
  )
}

MobileMenu.propTypes = {
  userID: PropTypes.string.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  showContact: PropTypes.func.isRequired
}
