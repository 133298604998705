import React from 'react'
import { Router } from '@reach/router'
import { Helmet } from 'react-helmet'
import NotFoundPage from './pages/not-found/'
import BlankCanvasLayout from './layouts/blank-canvas'
import Dashboard from './pages/dashboard/'
import UserCreationByInvitation from './pages/user-creation-by-invitation'
import NewUserInvitation from './pages/admin/new-user-invitation'
import Tutorial from './pages/tutorial'
import { ApolloProvider } from '@apollo/client'
import { ResponsiveProvider } from './context/responsive'
import { client } from './apollo-client'
import { Route } from './components/route'
import './assets/css/main.scss'

const App = function App () {
  return (
    <ResponsiveProvider>
      <ApolloProvider client={client}>
        <Helmet titleTemplate='%s | DispatcherLite' defaultTitle='Dispatcher Lite'/>
        <Router>
          <Route
            path='/'
            component={Dashboard}
            pageName='home'
          />
          <Route
            path='/user-creation/:uuid'
            pageName='createUserByInvitation'
            component={UserCreationByInvitation}
            requiresAuth={false}
            layout={BlankCanvasLayout}
            centeredOnScreen={true}
          />
          <Route
            path='/admin/new-user-invitation'
            pageName='newUserInvitation'
            component={NewUserInvitation}
            requiresAuth={true}
            layout={BlankCanvasLayout}
            centeredOnScreen={true}
          />
          <Route
            path='/not-found'
            component={NotFoundPage}
            pageName='not-found'
            requiresAuth={false}
          />
          <Route
            path='/tutorial'
            pageName='tutorialVideo'
            component={Tutorial}
            requiresAuth={false}
            layout={BlankCanvasLayout}
            showHeader={true}
          />
        </Router>
      </ApolloProvider>
    </ResponsiveProvider>
  )
}

export default App
