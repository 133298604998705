import { gql } from '@apollo/client'

export const ATTACHED_ACCOUNTS = gql`
  query getAllAttachedAccounts ($ids: [ID!]!, $cascadeSearch: Boolean!) {
  getAllAttachedAccounts(ids: $ids, cascadeSearch: $cascadeSearch) {
    companyName
    id
  }
}
`

export const BASIC_USER_INFO = gql`
  query user ($id: ID!){
    user(userID: $id){
      appMetadata {
        bd {
          salesforceAccounts
        }
      }
    }
  }
`
