import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { HAULER_ACTIONS } from './constants'
import { ScheduledJobsTableHeader } from './table-header' // *
import { ScheduledJobsTableRow } from './table-row' // *
import ScheduledJobsMarkCompleteButton from './mark-complete-button' // *
import './scheduled-jobs.scss'

export function ScheduledJobsTable ({ haulerScheduledServicesByDate, requestRefetch }) {
  const jobs = haulerScheduledServicesByDate.filter(service =>
    service.products[0].pageSection === (HAULER_ACTIONS.pending) ||
    service.products[0].pageSection === (HAULER_ACTIONS.confirmed) ||
    service.products[0].pageSection === (HAULER_ACTIONS.none)
  )
  const cancelledJobs = haulerScheduledServicesByDate.filter(service => service.products[0].pageSection === HAULER_ACTIONS.cancelled)
  const checkComplete = jobs.filter(service => service.products[0].haulerActions.indexOf(HAULER_ACTIONS.serviceComplete) === -1).length
  const [completeAll, setCompleteAll] = useState(false)
  const [disabledRows, setDisabledRows] = useState({ isEditing: false, editingIndex: -1 })
  const [markAllCompletionStep, setMarkAllCompletionStep] = useState(0)

  return (
    <table className='scheduled-jobs__table'>
      <ScheduledJobsTableHeader />
      <tbody>
        {jobs.length ? (
          <React.Fragment>
            {
              jobs.map((job, index) => {
                return <ScheduledJobsTableRow
                  service = {job}
                  canAction = {true}
                  completeAll = {completeAll}
                  index = {index}
                  disabledRows = {disabledRows}
                  setDisabledRows = {setDisabledRows}
                  key = {`jobs-${index}`}
                  requestRefetch = {requestRefetch}
                  markAllEditing = {jobs.length !== checkComplete && markAllCompletionStep === 1}/>
              })
            }
            <tr className="scheduled-jobs__mark-complete-row">
              <ScheduledJobsMarkCompleteButton
                markAll = {setCompleteAll}
                allComplete = {jobs.length === checkComplete}
                disabledBtn = {disabledRows.isEditing}
                completionStep = {markAllCompletionStep}
                setCompletionStep = {setMarkAllCompletionStep}
              />
            </tr>
          </React.Fragment>
        ) : null }
        { cancelledJobs.length ? (
          <React.Fragment>
            {
              cancelledJobs.map((cancelledJob, index) => {
                return <ScheduledJobsTableRow
                  service = {cancelledJob}
                  canAction = {false}
                  completeAll = {completeAll}
                  index = {index}
                  disabledRows = {disabledRows}
                  setDisabledRows = {setDisabledRows}
                  markAllEditing = {jobs.length !== checkComplete && markAllCompletionStep === 1}
                  key = {`cancelledJob-${index}`} />
              })
            }
          </React.Fragment>
        ) : null }
      </tbody>
    </table>
  )
}

ScheduledJobsTable.propTypes = {
  haulerScheduledServicesByDate: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          serviceType: PropTypes.string.isRequired,
          caseProductID: PropTypes.string.isRequired,
          size: PropTypes.number.isRequired,
          debris: PropTypes.string.isRequired,
          productStatus: PropTypes.string.isRequired,
          address: PropTypes.shape({
            street: PropTypes.string.isRequired,
            city: PropTypes.string.isRequired,
            postalCode: PropTypes.string.isRequired
          }),
          haulerActions: PropTypes.arrayOf(PropTypes.string).isRequired
        }).isRequired
      ).isRequired
    }).isRequired
  ),
  requestRefetch: PropTypes.func
}
