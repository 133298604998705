import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import './dropdown-select.scss'
import { classNamePropTypes } from '../../utils/classNamePropTypes'

export function DropdownSelect ({ selected, disabled, errorState, selectOptions, setSelected, className }) {
  function selectBoxOnChange (event) {
    setSelected(event.target.value)
  }

  return (
    <div className={classNames(className, 'dropdown-select__wrapper',
      {
        'dropdown-select__wrapper_disabled': disabled
      })}>
      <FontAwesomeIcon
        icon={'sort-down'}
        className={classNames('dropdown-select__sort-down',
          {
            'dropdown-select__sort-down_disabled': disabled
          }
        )}
      />
      <select value={selected} disabled={disabled} required={true} onChange={selectBoxOnChange}>
        <option value="">Select</option>
        {selectOptions.map((opt, index) =>
          (
            <option
              value={opt.value}
              key={index}
            >
              {opt.label}
            </option>
          )
        )}
      </select>
    </div>
  )
}

DropdownSelect.propTypes = {
  selected: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  errorState: PropTypes.bool,
  selectOptions: PropTypes.array.isRequired,
  className: classNamePropTypes
}
