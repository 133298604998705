import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { passwordStrength } from 'check-password-strength'
import { CREATE_USER } from './graphql'
import { Alert } from '../alert'
import { Tooltip } from '../tooltip'
import { Loader } from '../loader'
import ShowHidePassword from '../inputs/show-hide-password'
import { LOGIN_URL_TO_HOMEPAGE } from '../../utils/constants'
import { ERRORMESSAGES } from './constants'
import './user-creation-form.scss'

const UserCreationForm = function UserCreationForm ({
  uuid,
  preEnteredEmail = '',
  setHeaderMessage,
  setSecondaryHeaderMessage,
  applicationID
}) {
  const [formError, setError] = useState('')
  const [showError, setShowError] = useState(false)
  const [userEmail, setEmail] = useState(preEnteredEmail)
  const [acctCreationSuccess, setAcctCreationSuccess] = useState(false)
  const [
    createUser, { loading: activeLoading }
  ] = useMutation(CREATE_USER)

  const isReadOnly = preEnteredEmail !== ''

  function handleInputChange (e) {
    const daForm = e.target.form
    const enteredValue = e.target.value
    const inputName = e.target.name
    const confirmPasswordField = daForm.elements['confirm-password']

    setError('')

    if (inputName === 'new-password' && confirmPasswordField.value !== '') {
      confirmPasswordField.value = ''
      return
    }

    if (inputName === 'username') {
      return setEmail(enteredValue)
    }
  }

  function passwordValidityState (isValid, checkMatch = false) {
    if (isValid) {
      setShowError(false)
      return setError('')
    }

    setError(checkMatch ? 'passwordMatch' : 'password')
    setShowError(true)
  }

  function checkPasswordStrength (password) {
    const strength = passwordStrength(password).id
    return strength >= 2
  }

  function validateSame (formField, fieldToMatch) {
    const daForm = formField.form

    if (!Object.hasOwnProperty.call(daForm.elements, fieldToMatch)) {
      return false
    }

    return daForm.elements[fieldToMatch].value === formField.value
  }

  function createUserSubmission (e) {
    e.preventDefault()
    const daFormElements = e.target.elements

    const mutationObj = {
      uuid: daFormElements.uuid.value,
      emailAddress: daFormElements.username.value,
      password: daFormElements['new-password'].value,
      invitationID: daFormElements['invite-id'].value
    }

    if (!checkPasswordStrength(daFormElements['new-password'].value)) {
      return passwordValidityState(false, false)
    } else if (!validateSame(daFormElements['confirm-password'], 'new-password')) {
      return passwordValidityState(false, true)
    } else {
      passwordValidityState(true)
    }

    return createUser({
      variables: mutationObj
    })
      .then(() => {
        setAcctCreationSuccess(true)
        setHeaderMessage('Your Account Has Been Created!')
        setSecondaryHeaderMessage('Click below to log in to your new account, otherwise you will be redirected in 5 seconds.')
      })
      .catch(() => {
        setError('bigYikes')
        setShowError(true)
      })
  }

  function setTooltip () {
    return (
      <Tooltip
        name='Password Strength Rules'
        styleNames='user-creation-form__tooltip'
        trigger={(<FontAwesomeIcon icon='question-circle' className=''/>)}
        position='top-center'>
        <div className='user-creation-form__tooltip-content'>
          <p>Password Strength Requirements</p>
          <ul>
            <li>Minimum 8 Characters</li>
            <li>At least 1 special character (!@#$%^&*)</li>
            <li>At least 1 lowercase (a-z), 1 uppercase (A-Z), and 1 number (0-9)</li>
          </ul>
        </div>
      </Tooltip>
    )
  }

  if (acctCreationSuccess) {
    setTimeout(() => {
      window.location = LOGIN_URL_TO_HOMEPAGE
      return false
    }, 5000)
    return (
      <a
        href={LOGIN_URL_TO_HOMEPAGE}
        className='user-creation-form__submit-btn user-creation-form__submit-btn--shortened button__submit button__submit--primary_green'
      >
        GO TO LOG IN
      </a>
    )
  }

  return (
    <form
      onSubmit={createUserSubmission}
      className='user-creation-form'
      name='user-creation-submission'
    >
      {
        formError !== '' &&
        <Alert
          alertType = 'error'
          isOpen = {showError}
          setIsOpen = {setShowError}
          errorMessage={ERRORMESSAGES[formError]}
        />
      }
      <label
        className={classNames({
          'user-creation-form_error': formError !== ''
        })}
      >
        Email Address
        <input
          name='username'
          type='email'
          className={classNames({
            'user-creation-form__input_error': formError === 'email'
          })}
          required={true}
          defaultValue={userEmail}
          readOnly={isReadOnly}
          autoComplete='new-password'
          onChange={handleInputChange}
        />
      </label>
      <label>
        Create Password
        {setTooltip()}
        <ShowHidePassword
          wrapperClasses='user-creation-form__input-wrapper'
          inputClasses={classNames({
            'user-creation-form__input_error': (formError.indexOf('password') !== -1)
          })}
          inputName='new-password'
          isNewPassword={true}
          onChangeHandler={handleInputChange}
        />
      </label>
      <label>
        Confirm Password
        <input
          name='confirm-password'
          className={classNames({
            'user-creation-form__input_error': formError === 'passwordMatch'
          })}
          type='password'
          required={true}
          autoComplete='new-password'
          onBlur={e => passwordValidityState(validateSame(e.target, 'new-password'), true)}
        />
      </label>
      <input
        type='hidden'
        defaultValue={uuid}
        name='uuid'
      />
      <input
        type='hidden'
        defaultValue={applicationID}
        name='invite-id'
      />
      <button
        className='user-creation-form__submit-btn button__submit button__submit--primary_green'
        type='submit'
        disabled={activeLoading}
      >
        { activeLoading
          ? <Loader isFetching={true}/>
          : 'Create Account'
        }
      </button>
    </form>
  )
}

UserCreationForm.propTypes = {
  uuid: PropTypes.string.isRequired,
  preEnteredEmail: PropTypes.string,
  setHeaderMessage: PropTypes.func.isRequired,
  setSecondaryHeaderMessage: PropTypes.func.isRequired,
  applicationID: PropTypes.string.isRequired
}

export default UserCreationForm
