import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { SiteHeader } from '../../components/site-header'
import { BASIC_USER_INFO } from './graphql'
import {
  DISPATCHER_LITE_CLIENT_ID,
  SUPPORT_CONTACTS,
  identityTokenPropTypes
} from '../../utils/constants'
import './app-layout.scss'
import { Loader } from '../../components/loader'
import { ErrorPage } from '../../components/error-page'

function getCustomerID (propID, tokenData) {
  const { sub, aud } = tokenData
  if (propID) {
    return propID
  }

  if (aud === DISPATCHER_LITE_CLIENT_ID && sub) {
    return tokenData.sub
  }

  return ''
}

const AppLayout = function AppLayout ({ banner, children, pageName, IDToken, customerID: propCustomerID }) {
  const customerID = getCustomerID(propCustomerID, IDToken)
  const {
    loading: userLoading,
    error: userError,
    data: userData
  } = useQuery(BASIC_USER_INFO, {
    variables: {
      id: customerID
    }
  })

  if (userLoading) {
    return (
      <div className='app-layout__loader'>
        <Loader isFetching={true}/>
      </div>
    )
  }

  function renderMain () {
    if (userError || userData == null || userData.user == null || userData.user.appMetadata.bd.salesforceAccounts.length === 0) {
      return (
        <ErrorPage>
          <p className='app-layout__error-text'>
            Sorry, there was an error loading your dashboard. Please email <a href={`mailto:${SUPPORT_CONTACTS.email}`}>{SUPPORT_CONTACTS.email}</a> or call {SUPPORT_CONTACTS.phone}.
          </p>
        </ErrorPage>
      )
    }

    const accounts = userData.user.appMetadata.bd.salesforceAccounts

    return React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(
          child,
          {
            accounts
          }
        )
      }
    })
  }

  let email = ''
  let isInternalUser = false
  let permissions = []

  if (IDToken.aud === DISPATCHER_LITE_CLIENT_ID) {
    email = IDToken.email
    permissions = IDToken.permissions
    isInternalUser = false
  }

  return (
    <div className='app-layout app-layout_theme_dl'>
      <SiteHeader
        isUserLoggedIn={true}
        customerID={customerID}
        userID={email}
        permissions={permissions}
        isInternalUser={isInternalUser}
      />
      {banner}
      <main id={`${pageName}-page`}>
        {renderMain()}
      </main>
      <footer className='app-layout__footer app-layout__footer_theme_dl'>
        <p>&copy; {new Date().getFullYear()}, Dispatcher.com LLC</p>
      </footer>
    </div>
  )
}

AppLayout.propTypes = {
  pageName: PropTypes.string.isRequired,
  IDToken: PropTypes.shape(identityTokenPropTypes).isRequired,
  customerID: PropTypes.string,
  banner: PropTypes.elementType
}

export default AppLayout
