/**
 * File mostly exists so we don't have to .extend(utc) every time we use dayjs.
 * https://day.js.org/
 * https://day.js.org/docs/en/plugin/utc
 */

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(utc)
dayjs.extend(customParseFormat)

export {
  dayjs
}
