import React, { useRef } from 'react'
import classNames from 'classnames'
import { useExpand } from '../../hooks/useExpand'
import PropTypes from 'prop-types'
import './tooltip.scss'

export function Tooltip ({ children, trigger, position, name, styleNames = '' }) {
  const parentRef = useRef()
  const { isOpened, toggle } = useExpand(parentRef)
  return (
    <span ref={parentRef} className={classNames('tooltip', styleNames)} role='tooltip' aria-label={name}>
      <span onMouseEnter={toggle}>{trigger}</span>
      {isOpened && (
        <div className={`tooltip__wrapper tooltip__wrapper_${position}`}>
          <div className={`tooltip__wrapper__arrow tooltip__wrapper__arrow_${position}`}></div>
          {children}
        </div>
      )}
    </span>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  styleNames: PropTypes.string,
  trigger: PropTypes.node.isRequired,
  position: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}
