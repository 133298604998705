import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useMutation } from '@apollo/client'
import { Loader } from '../loader'
import { DECLINE_SERVICE } from './graphql'
import { DropdownSelect } from '../dropdown-select/'
import { DECLINE_VALUES } from './constants'
export function NewRequestsDeclineForm ({
  setDeclineConfirmation,
  serviceType,
  serviceID,
  declineReason,
  setDeclineReason,
  setShowDeclineReason,
  isDetails,
  declineDetails,
  setDeclineDetails,
  setError
}) {
  const [errorReason, setErrorReason] = useState(false)
  const [errorDetails, setErrorDetails] = useState(false)
  const [
    declineService, { loading: declineServiceLoading }
  ] = useMutation(DECLINE_SERVICE)
  const needsReasonSelected = (serviceType !== 'PICK_UP')

  function submitResponse (event) {
    event.preventDefault()
    let error = false

    if (needsReasonSelected && declineReason === '') {
      setErrorReason(true)
      error = true
    }

    if (declineDetails === '') {
      setErrorDetails(true)
      error = true
    }

    if (error) {
      return
    }

    const inputData = {
      order: serviceID,
      orderType: serviceType,
      details: declineDetails
    }
    if (needsReasonSelected) {
      inputData.type = declineReason
    }

    declineService({
      variables: {
        input: inputData
      }
    })
      .then(() => {
        setShowDeclineReason(true)
      })
      .catch(err => {
        console.error('error declining new request', err)
        setError(true)
      })
  }

  function handleTextArea (event) {
    const inputValue = event.target.value

    if (inputValue !== '') {
      setErrorDetails(false)
    }
    setDeclineDetails(inputValue)
  }

  return (
    declineServiceLoading
      ? <Loader isFetching={true} />
      : <form
        onSubmit={submitResponse}
        className={classNames('new-requests__decline-form', { 'new-requests__decline-form_details': isDetails })}>
        <div className={classNames({ 'new-requests__decline-form_details_wrapper': isDetails })}>
          {/* PICKUP is the only service type that reasons are not required. */}
          {needsReasonSelected &&
          <div className={classNames({
            'new-requests__decline-form_details_form new-requests__decline-form_details_form_mr': isDetails,
            'new-requests__decline-input_error': errorReason
          })}>
            <p className='new-requests__decline-form-label'>Reason</p>
            <DropdownSelect
              selected = {declineReason}
              selectOptions = {DECLINE_VALUES}
              errorState={false}
              setSelected = {setDeclineReason}>
            </DropdownSelect>
          </div>
          }
          <div className={classNames({ 'new-requests__decline-form_details_form': isDetails })}>
            <label htmlFor='decline-details' className='new-requests__decline-form-label new-requests__decline-form-label-details'>Details</label>
            <textarea name='decline-details'
              className={
                classNames('new-requests__decline-form-text-area',
                  {
                    'new-requests__decline-form-text-area_mt': isDetails,
                    'new-requests__decline-input_error': errorDetails
                  })
              }
              defaultValue={declineDetails}
              onBlur={handleTextArea}
              maxLength='200'
            />
          </div>
        </div>
        <button
          type='submit'
          className={classNames('button__submit button__submit--primary_warning new-requests__decline-form-submit', {
            'new-requests__decline-form-submit_details': isDetails
          })}>
        Submit Response
        </button>
        <button
          className={classNames('button__submit button__submit--primary_white new-requests__decline-form-cancel', { 'new-requests__decline-form-cancel_details': isDetails })}
          onClick={() => setDeclineConfirmation(false)}>
          Cancel
        </button>
      </form>
  )
}

NewRequestsDeclineForm.propTypes = {
  setDeclineConfirmation: PropTypes.func.isRequired,
  serviceType: PropTypes.string.isRequired,
  serviceID: PropTypes.string.isRequired,
  declineReason: PropTypes.string.isRequired,
  setDeclineReason: PropTypes.func.isRequired,
  setShowDeclineReason: PropTypes.func.isRequired,
  isDetails: PropTypes.bool,
  declineDetails: PropTypes.string.isRequired,
  setDeclineDetails: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
}
