import { gql } from '@apollo/client'
import { sharedAvailabilityProperties } from './fragments'

export const UPDATE_AVAILABILITY_ACTIVE_STATE = gql`
  mutation UpdateAvailabilityActiveState($input: UpdateAvailabilityActiveStateInput!) {
    updateAvailabilityActiveState(input: $input) {
      ${sharedAvailabilityProperties}
      ... on SizedCurrentAvailability {
        sizeID
        size {
          id
          name
        }
      }
    }
  }
`

export const UPDATE_AVAILABILITY_NEXT_AVAILABLE_SERVICE = gql`
  mutation UpdateAvailabilityNextAvailableService($input: UpdateAvailabilityNextAvailableServiceInput!) {
    updateAvailabilityNextAvailableService(input: $input) {
      ${sharedAvailabilityProperties}
      ... on SizedCurrentAvailability {
        sizeID
        size {
          id
          name
        }
      }
    }
  }
`

export const UPDATE_AVAILABILITY_DAYS_OUT = gql`
  mutation UpdateAvailabilityDaysOut($input: UpdateAvailabilityDaysOutInput!) {
    updateAvailabilityDaysOut(input: $input) {
      ${sharedAvailabilityProperties}
      ... on SizedCurrentAvailability {
        sizeID
        size {
          id
          name
        }
      }
    }
  }
`

export const UPDATE_AVAILABILITY_MUST_CALL = gql`
  mutation UpdateAvailabilityMustCall($input: UpdateAvailabilityMustCallInput!) {
    updateAvailabilityMustCall(input: $input) {
      ${sharedAvailabilityProperties}
      ... on SizedCurrentAvailability {
        sizeID
        size {
          id
          name
        }
      }
    }
  }
`

export const MARK_SERVICE_AS_COMPLETE = gql`
  mutation markServiceAsComplete($service: ID!) {
    markServiceAsComplete(service: $service)
  }
`
