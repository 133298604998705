import { gql } from '@apollo/client'

export const HAULER_NEW_REQUESTS = gql`
  query haulerNewCaseRequests($haulerID: ID!) {
    haulerNewCaseRequests(haulerID: $haulerID) {
      ID
      status
      isEscalated
      issueSubType
      issueDetails
      orderNumber
      onSiteContact {
        phone
        fullName
      }
      products {
        deliveryDate
        pickupDate
        serviceType
        caseProductID
        deliveryInstructions
        specialRequests
        size
        debris
        productStatus
        address {
          street
          city
          state
          postalCode
        }
        haulerActions
      }
    }
  }
`

export const CONFIRM_SERVICE = gql`
  mutation confirmFulfillment($input: ConfirmFulfillmentCase!){
    confirmFulfillment(input: $input)
  }
`
export const DECLINE_SERVICE = gql`
  mutation declineFulfillment($input: DeclineFulfillmentCase!){
    declineFulfillment(input: $input)
  }
`
