export const sharedAvailabilityProperties = `
  id
  deliveryType
  sameDayActive
  sameDayCutoffTime
  sameDayCutoffTime
  nextDayActive
  nextDayCutoffTime
  saturdayActive
  saturdayCutoffTime
  sundayActive
  sundayCutoffTime
  nextAvailableService
  deliveryDate
  daysOut
  daysOutCutoffTime
  mustCall
`
