import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import { addIcons } from './utils/icons'

addIcons()

// import * as serviceWorker from './service-worker'

ReactDOM.render(<App />, document.getElementById('root'))

// serviceWorker.register()
