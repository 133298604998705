import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './modal.scss'

const modalRoot = document.getElementById('modal')

const Modal = ({
  children,
  isOpen = false,
  setIsOpen,
  title,
  canPrint,
  onCloseEvent,
  showModalContent,
  modalClassName
}) => {
  if (!modalRoot) {
    throw new Error('Place container with id "modal" next to your root container')
  }

  function handleModalClose () {
    setIsOpen(false)
    if (onCloseEvent) {
      onCloseEvent()
    }
  }

  const element = document.createElement('div')

  useEffect(() => {
    modalRoot.appendChild(element)

    return () => {
      modalRoot.removeChild(element)
    }
  }, [isOpen, element])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
      return
    }

    document.body.style.overflow = 'visible'
  }, [isOpen])

  function printDetails () {
    window.print()
  }

  return isOpen ? ReactDOM.createPortal(
    <div className='modal'>
      <div className={classNames('modal__main', `modal__main__${modalClassName}`)}>
        {showModalContent && <div className='modal__main__content'>
          <div className='modal__main__content__header'>
            <div className='modal__main__content__header__title'>{title}</div>
            {canPrint &&
            <span
              className='modal__main__content__header__print fa-stack'
              onClick={() => printDetails()}
            >
              <FontAwesomeIcon
                icon={'circle'}
                className='modal__main__content__header__print__icon-circle fa-stack-2x'
              />
              <FontAwesomeIcon
                icon={['fas', 'print']}
                className='modal__main__content__header__print__icon-print fa-stack-1x'
              />
            </span>
            }
          </div>
          <FontAwesomeIcon
            icon={['far', 'times']}
            className='modal__main__content__header__close'
            onClick={handleModalClose}
          />
        </div>}
        {children}
      </div>
    </div>,
    element
  ) : null
}

Modal.defaultProps = {
  canPrint: false,
  showModalContent: true
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  canPrint: PropTypes.bool,
  onCloseEvent: PropTypes.func,
  showModalContent: PropTypes.bool,
  modalClassName: PropTypes.string
}

export default Modal
