import Cookies from 'js-cookie'

/**
 * An everyday debounce function. Borrowed from Underscore https://underscorejs.org
 * @param {Function} fn
 * @param {Integer} wait time to wait in milliseconds
 * @returns {function} the original callback
 */
export function debounce (fn, wait) {
  let timeout = null
  const timer = wait

  const debounced = function (...args) {
    const later = () => {
      timeout = null

      fn.apply(this, args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, timer)

    if (!timeout) {
      fn.apply(this, args)
    }
  }

  debounced.cancel = () => {
    clearTimeout(timeout)
    timeout = null
  }

  return debounced
}

/**
 * Log a user out of Dispatcher Lite
 * @requires js-cookie
 */
export function logoutUser () {
  Cookies.remove('budgetdumpster_auth_id_token', { domain: '.dispatcher.com', path: '/' })
  window.location.reload()
}
