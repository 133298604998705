import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './error-page.scss'

export function ErrorPage ({ children }) {
  return (
    <div className='error-page__wrapper'>
      <FontAwesomeIcon icon='exclamation-circle' className='error-page__icon' />
      <h1 className='error-page__header'>Error</h1>
      {children}
    </div>
  )
}

ErrorPage.propTypes = {
  children: PropTypes.node
}

ErrorPage.defaultProps = {
  children: null
}
