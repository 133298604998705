import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import './horizontal-scroll.scss'

export function HorizontalScroll ({ indicatorEls, containerEl, leftViewPortOffset }) {
  const viewportWidth = window.visualViewport.width

  function scroll (offset) {
    containerEl.current.scrollLeft += offset
  }

  function checkInViewPort (el) {
    const rect = el.getBoundingClientRect()
    return (
      rect.left < leftViewPortOffset ||
      rect.right > viewportWidth
    )
  }

  return (
    <div className='horizontal-scroll'>
      <FontAwesomeIcon
        aria-label='scroll left'
        role='button'
        icon={'chevron-left'}
        className='horizontal-scroll__arrows horizontal-scroll__arrows'
        onClick={() => scroll(-viewportWidth / indicatorEls.length)}/>
      {indicatorEls && indicatorEls.map((el, index) => {
        return <FontAwesomeIcon
          icon={'circle'}
          key={index}
          className={classNames('horizontal-scroll__indicator-dots', {
            'horizontal-scroll__indicator-dots_dark': !checkInViewPort(el)
          })}/>
      })}
      <FontAwesomeIcon
        aria-label='scroll right'
        roll='button'
        icon={'chevron-right'}
        className='horizontal-scroll__arrows horizontal-scroll__arrows_right'
        onClick={() => scroll(viewportWidth / indicatorEls.length)}/>
    </div>
  )
}

HorizontalScroll.defaultProps = {
  leftViewPortOffset: 0
}

HorizontalScroll.propTypes = {
  indicatorEls: PropTypes.array,
  containerEl: PropTypes.object,
  leftViewPortOffset: PropTypes.number
}
