import React from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-spinkit'
import classNames from 'classnames'
import { classNamePropTypes } from '../../utils/classNamePropTypes'
import './loader.scss'

export function Loader ({ isFetching, children, className, isInline }) {
  if (isFetching) {
    return (
      <div className={classNames('loader__wrapper', className, {
        loader__wrapper_inline: isInline
      })}>
        <Spinner name='circle' className='loader__icon' fadeIn='none' />
      </div>
    )
  }

  return children
}

Loader.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  children: PropTypes.node,
  className: classNamePropTypes,
  isInline: PropTypes.bool
}

Loader.defaultProps = {
  children: null,
  isInline: false
}
