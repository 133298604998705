import React from 'react'

const NotFoundPage = function NotFoundPage () {
  return (
    <div id='not-found-page'>
      <h1 className='flex h-screen justify-center pt-16 md:pt-0 md:items-center text-5xl bg-gray-light text-blue'>Not Found</h1>
    </div>
  )
}

export default NotFoundPage
