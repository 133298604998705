import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { dayjs } from '../../utils/dayjs'
import { ResponsiveContext } from '../../context/responsive'
import {
  DAYS_OUT,
  GRAPHQL_AVAILABILITY_DELIVERY_DAY,
  NEXT_AVAILABLE_SERVICE,
  MUST_CALL
} from './constants'
import {
  sizedAvailabilityProps,
  currentAvailabilityProps,
  determineNextAvailableService,
  formatTime
} from './utils'
import { CheckboxWrapper } from '../inputs/checkbox'
import { CalendarWrapper } from '../inputs/date-picker'
import { NumberWrapper } from '../inputs/number'
import { useQuery, useMutation } from '@apollo/client'
import {
  UPDATE_AVAILABILITY_ACTIVE_STATE,
  UPDATE_AVAILABILITY_DAYS_OUT,
  UPDATE_AVAILABILITY_NEXT_AVAILABLE_SERVICE,
  UPDATE_AVAILABILITY_MUST_CALL
} from '../../graphql/mutations'
import { classNamePropTypes } from '../../utils/classNamePropTypes'
import { AvailabilityTableHeaders } from './table-headers'
import { Alert } from '../alert/'
import { GET_AGGREGATE_AVAILABILITY } from '../../graphql/queries'
import { HorizontalScroll } from '../horizontal-scroll'

export function AvailabilityTable ({ availabilities }) {
  const { isMDScreen } = useContext(ResponsiveContext)
  const [showError, setShowError] = useState(false)
  const [updating, setUpdating] = useState({
    availability: undefined,
    day: undefined
  })
  const [scrollEls, setScrollEls] = useState(null)

  const scrollRef = React.useCallback(node => {
    if (node !== null) {
      setScrollEls(node)
    }
  }, [])

  const scrollableRef = React.createRef()

  function handleScroll () {
    setScrollEls(scrollRef)
  }

  function formatScrollElements () {
    if (scrollEls) {
      return Object.values(scrollEls.children).slice(1).map(el => el)
    }
    return null
  }

  const [applyAllLoading, setApplyAllLoading] = useState({
    sameDayActive: false,
    nextDayActive: false,
    daysOut: false,
    nextAvailableService: false,
    saturdayActive: false,
    sundayActive: false,
    mustCall: false
  })

  const {
    data: { haulerAvailabilities: { aggregate = {} } }, refetch
  } = useQuery(GET_AGGREGATE_AVAILABILITY)

  const [applyAllHeaders, setApplyAllHeaders] = useState(aggregate)

  const [
    updateAvailabilityActiveState, { loading: activeLoading }
  ] = useMutation(UPDATE_AVAILABILITY_ACTIVE_STATE)
  const [
    updateAvailabilityNextAvailableService,
    { loading: nextAvailableServiceLoading }
  ] = useMutation(UPDATE_AVAILABILITY_NEXT_AVAILABLE_SERVICE)
  const [
    updateAvailabilityDaysOut,
    { loading: dayOutLoading }
  ] = useMutation(UPDATE_AVAILABILITY_DAYS_OUT)
  const [
    updateAvailabilityMustCall,
    { loading: mustCallLoading }
  ] = useMutation(UPDATE_AVAILABILITY_MUST_CALL)

  function refetchApplyAll () {
    return refetch()
      .catch(err => console.error('error fetching apply all aggregate', err))
  }

  const loading = activeLoading || nextAvailableServiceLoading || dayOutLoading || mustCallLoading

  function changeAvailabilityActiveState (updatingAvailability, deliveryDay, isActive, field, lastAvail) {
    setUpdating({ availability: updatingAvailability, day: deliveryDay })
    updateAvailabilityActiveState({
      variables: {
        input: {
          deliveryType: updatingAvailability.deliveryType,
          id: updatingAvailability.id,
          deliveryDay,
          isActive
        }
      }
    })
      .then(() => {
        refetchApplyAll().then((res) => {
          setApplyAllHeaders(res.data.haulerAvailabilities.aggregate)
        })
      })
      .catch((err) => {
        console.error('error updating active availability', err)
        setShowError(true)
      })
      .finally(() => {
        setApplyAllLoading(prevValue => ({ ...prevValue, [field]: false }))
        setUpdating({ availability: undefined, day: undefined })
      })
  }

  function changeAvailabilityNextAvailableService (updatingAvailability, nextAvailableService) {
    setUpdating({ availability: updatingAvailability, day: NEXT_AVAILABLE_SERVICE })
    updateAvailabilityNextAvailableService({
      variables: {
        input: {
          id: updatingAvailability.id,
          deliveryType: updatingAvailability.deliveryType,
          nextAvailableService
        }
      }
    })
      .then(() => {
        refetchApplyAll().then((res) => {
          setApplyAllHeaders(res.data.haulerAvailabilities.aggregate)
        })
      })
      .catch((err) => {
        console.error('error updating next available service availability', err)
        setShowError(true)
      })
      .finally(() => {
        setUpdating({ availability: undefined, day: undefined })
        setApplyAllLoading(prevValue => ({ ...prevValue, nextAvailableService: false }))
      })
  }

  function changeAvailabilityDaysOut (updatingAvailability, daysOut) {
    setUpdating({ availability: updatingAvailability, day: DAYS_OUT })
    updateAvailabilityDaysOut({
      variables: {
        input: {
          id: updatingAvailability.id,
          deliveryType: updatingAvailability.deliveryType,
          daysOut
        }
      }
    })
      .then(() => {
        refetchApplyAll().then((res) => {
          setApplyAllHeaders(res.data.haulerAvailabilities.aggregate)
        })
      })
      .catch((err) => {
        console.error('error updating days out availability', err)
        setShowError(true)
      })
      .finally(() => {
        setUpdating({ availability: undefined, day: undefined })
        setApplyAllLoading(prevValue => ({ ...prevValue, daysOut: false }))
      })
  }

  function changeAvailabilityMustCall (updatingAvailability, mustCall) {
    setUpdating({ availability: updatingAvailability, day: MUST_CALL })
    updateAvailabilityMustCall({
      variables: {
        input: {
          id: updatingAvailability.id,
          deliveryType: updatingAvailability.deliveryType,
          mustCall
        }
      }
    })
      .then(() => {
        refetchApplyAll().then((res) => {
          setApplyAllHeaders(res.data.haulerAvailabilities.aggregate)
        })
      })
      .catch((err) => {
        console.error('error updating must call availability', err)
        setShowError(true)
      })
      .finally(() => {
        setUpdating({ availability: undefined, day: undefined })
        setApplyAllLoading(prevValue => ({ ...prevValue, mustCall: false }))
      })
  }

  const isDisabled = Boolean(loading && updating.availability)

  function renderAvailabilityType (availabilityType, type) {
    const cutoffTimes = [
      '',
      availabilityType[0] ? formatTime(availabilityType[0].sameDayCutoffTime) : '',
      availabilityType[0] ? formatTime(availabilityType[0].nextDayCutoffTime) : '',
      availabilityType[0] ? formatTime(availabilityType[0].daysOutCutoffTime) : '',
      '-:--',
      availabilityType[0] ? formatTime(availabilityType[0].saturdayCutoffTime) : '',
      availabilityType[0] ? formatTime(availabilityType[0].sundayCutoffTime) : ''
    ]

    return (
      <React.Fragment key={type}>
        <thead>
          <tr ref={scrollRef} className='availability__table-header'>
            <th className='availability__table-header_border_right'> Cutoff Time </th>
            {cutoffTimes.map((cutoffTime, index) => {
              const isLastCutoffTime = index === cutoffTimes.length - 1
              return (
                <th
                  key={index + availabilityType}
                  className={
                    classNames({
                      'availability__table-header_border_right': isLastCutoffTime || index === 0 || index === 4
                    })
                  }>
                  {cutoffTime}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {availabilityType.map((availability) => {
            const mustCallLoading = loading && updating.day === MUST_CALL &&
              updating.availability.id === availability.id
            return (
              <tr key={availability.title} className='availability__cell-row'>
                <td
                  key={`${availability.title}`}
                  className='availability__cell-row_border_right availability__cell-row_bold availability__cell-row_first-col'>
                  {availability.title}
                </td>
                <td
                  key={`${availability.id}mustCall`}
                  className='availability__cell-row_border_right'>
                  <CheckboxWrapper
                    checked={availability.mustCall}
                    disabled={isDisabled}
                    loading={mustCallLoading || applyAllLoading.mustCall}
                    labelName={`${availability.title}mustCall`}
                    labelText={`${availability.title} Must Call`}
                    customCheckbox = {{
                      checkedIcon: 'phone-alt',
                      uncheckedIcon: 'phone-slash',
                      checkedLabelClasses: 'shared-inputs__checkbox_checked_must-call',
                      checkedIconClasses: 'shared-inputs__must-call-icon',
                      uncheckedIconClaseses: 'shared-inputs__no-must-call-icon'
                    }}
                    onChange={() => changeAvailabilityMustCall(
                      availability,
                      !availability.mustCall
                    )}
                  />
                </td>
                <td key={`${availability.id}sameDay`}>
                  <CheckboxWrapper
                    checked={availability.sameDayActive}
                    disabled={isDisabled}
                    loading={(loading &&
                      updating.day === GRAPHQL_AVAILABILITY_DELIVERY_DAY.SAME_DAY &&
                      updating.availability.id === availability.id) ||
                      applyAllLoading.sameDayActive ||
                      (mustCallLoading && availability.sameDayActive)}
                    labelName={`${availability.title}sameDayActive`}
                    labelText={`${availability.title} Same Day Acive`}
                    onChange={() => changeAvailabilityActiveState(
                      availability,
                      GRAPHQL_AVAILABILITY_DELIVERY_DAY.SAME_DAY,
                      !availability.sameDayActive,
                      'sameDayActive'
                    )}
                  />
                </td>
                <td key={`${availability.id}nextDay`}>
                  <CheckboxWrapper
                    checked={availability.nextDayActive}
                    disabled={isDisabled}
                    loading={(loading &&
                      updating.day === GRAPHQL_AVAILABILITY_DELIVERY_DAY.NEXT_DAY &&
                      updating.availability.id === availability.id) ||
                      applyAllLoading.nextDayActive ||
                      (mustCallLoading && availability.nextDayActive)}
                    labelName={`${availability.title}nextDayActive`}
                    labelText={`${availability.title} Next Day Active`}
                    onChange={() => changeAvailabilityActiveState(
                      availability,
                      GRAPHQL_AVAILABILITY_DELIVERY_DAY.NEXT_DAY,
                      !availability.nextDayActive,
                      'nextDayActive'
                    )}
                  />
                </td>
                <td key={`${availability.id}daysOut`} className='availability__cell-row_days-out availability__cell-row_middle'>
                  <NumberWrapper
                    value={availability.daysOut}
                    disabled={isDisabled}
                    loading={(loading &&
                      updating.day === DAYS_OUT &&
                      updating.availability.id === availability.id) ||
                      applyAllLoading.daysOut ||
                      (mustCallLoading && !!availability.daysOut)}
                    labelName={`${availability.title}daysOut`}
                    labelText={`${availability.title} Days Out`}
                    onChange={value => changeAvailabilityDaysOut(
                      availability,
                      value > 1 ? value : null
                    )}
                    min={2}
                  />
                </td>
                <td className='availability__cell-row_border_right availability__cell-row_middle' key={`${availability.id}nextAvailable`}>
                  <CalendarWrapper
                    value={determineNextAvailableService(availability)}
                    disabled={isDisabled}
                    minDate={new Date()}
                    loading={(loading &&
                      updating.day === NEXT_AVAILABLE_SERVICE &&
                      updating.availability.id === availability.id) ||
                      applyAllLoading.nextAvailableService ||
                      (mustCallLoading && !!determineNextAvailableService(availability))}
                    labelName={`${availability.title}nextAvailableService`}
                    labelText={`${availability.title} Next Available Service`}
                    onChange={value => changeAvailabilityNextAvailableService(
                      availability,
                      value === null ? null : dayjs(value).format('YYYY-MM-DD')
                    )}
                  />
                </td>
                <td key={`${availability.id}saturday`}>
                  <CheckboxWrapper
                    checked={availability.saturdayActive}
                    disabled={isDisabled}
                    loading={(loading &&
                      updating.day === GRAPHQL_AVAILABILITY_DELIVERY_DAY.SATURDAY &&
                      updating.availability.id === availability.id) ||
                      applyAllLoading.saturdayActive ||
                      (mustCallLoading && availability.saturdayActive)}
                    labelName={`${availability.title}saturdayActive`}
                    labelText={`${availability.title} Saturday Active`}
                    onChange={() => changeAvailabilityActiveState(
                      availability,
                      GRAPHQL_AVAILABILITY_DELIVERY_DAY.SATURDAY,
                      !availability.saturdayActive,
                      'saturdayActive'
                    )}
                  />
                </td>
                <td key={`${availability.id}sunday`}>
                  <CheckboxWrapper
                    checked={availability.sundayActive}
                    disabled={isDisabled}
                    loading={(loading &&
                      updating.day === GRAPHQL_AVAILABILITY_DELIVERY_DAY.SUNDAY &&
                      updating.availability.id === availability.id) ||
                      applyAllLoading.sundayActive ||
                      (mustCallLoading && availability.sundayActive)}
                    labelName={`${availability.title}sundyActive`}
                    labelText={`${availability.title} Sunday Active`}
                    onChange={() => changeAvailabilityActiveState(
                      availability,
                      GRAPHQL_AVAILABILITY_DELIVERY_DAY.SUNDAY,
                      !availability.sundayActive,
                      'sundayActive'
                    )}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <Alert
        alertType = 'error'
        isOpen = {showError}
        setIsOpen = {setShowError}/>
      {!isMDScreen && <div className='availability__scroll-container'>
        <HorizontalScroll
          indicatorEls = {formatScrollElements()}
          containerEl = {scrollableRef}
          leftViewPortOffset = {110}
        />
      </div>}
      <div className='availability__mobile-wrapper'>
        <div ref={scrollableRef} className='availability__scrollable-mobile' onScroll={() => handleScroll()}>
          <table className={classNames('availability availability__table')}>
            <AvailabilityTableHeaders
              availabilities={availabilities}
              applyAllHeaders={applyAllHeaders}
              setApplyAllHeaders={setApplyAllHeaders}
              applyAllLoading={applyAllLoading}
              setApplyAllLoading={setApplyAllLoading}
              changeAvailabilityActiveState={changeAvailabilityActiveState}
              changeAvailabilityNextAvailableService={changeAvailabilityNextAvailableService}
              changeAvailabilityDaysOut={changeAvailabilityDaysOut}
              changeAvailabilityMustCall={changeAvailabilityMustCall}
            />
            {Object.keys(availabilities).map((type) =>
              renderAvailabilityType(availabilities[type], type)
            )}
          </table>
        </div>
      </div>
    </React.Fragment>
  )
}

AvailabilityTable.propTypes = {
  availabilities: PropTypes.shape({
    newDeliveries: PropTypes.arrayOf(PropTypes.shape(sizedAvailabilityProps)).isRequired,
    emptyReturn: PropTypes.arrayOf(PropTypes.shape(currentAvailabilityProps)).isRequired,
    pickUp: PropTypes.arrayOf(PropTypes.shape(currentAvailabilityProps)).isRequired
  }),
  classNames: classNamePropTypes
}
