import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { values, uniq } from 'kyanite'
import { GRAPHQL_AVAILABILITY_DELIVERY_DAY } from './constants'
import { determineNextAvailableService } from './utils'
import { CheckboxWrapper } from '../inputs/checkbox'
import { CalendarWrapper } from '../inputs/date-picker'
import { NumberWrapper } from '../inputs/number'
import { dayjs } from '../../utils/dayjs'
import { Tooltip } from '../tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export function AvailabilityTableHeaders ({
  availabilities,
  applyAllHeaders,
  setApplyAllHeaders,
  applyAllLoading,
  setApplyAllLoading,
  changeAvailabilityActiveState,
  changeAvailabilityNextAvailableService,
  changeAvailabilityDaysOut,
  changeAvailabilityMustCall
}) {
  const columnTitles = ['Same Day', 'Next Day', 'Days Out', 'Next Available', 'Saturday', 'Sunday']
  const availabilityTypes = values(availabilities).reduce((acc, val) => acc.concat(val), [])

  function determineApplyAllNextAvailable () {
    const nextAvailable = []

    availabilityTypes.forEach(avail => {
      nextAvailable.push(determineNextAvailableService(avail))
    })

    const nextAvailableDate = uniq(nextAvailable)

    if (nextAvailableDate.length === 1 && Date.parse(nextAvailableDate[0])) {
      return nextAvailableDate[0]
    }

    return null
  }

  function applyAll (value, applyAllType, type, field) {
    setApplyAllHeaders(prevValue => ({ ...prevValue, [field]: value }))

    availabilityTypes.forEach((availability) => {
      if (availability[field] !== value) {
        setApplyAllLoading(prevValue => ({ ...prevValue, [field]: true }))
        determineChangeAvailability(applyAllType, availability, value, type, field)
      }
    })
  }

  function determineChangeAvailability (availabilityType, availability, value, type, field) {
    switch (availabilityType) {
      case 'active':
        changeAvailabilityActiveState(availability, type, value, field)
        break
      case 'daysOut':
        changeAvailabilityDaysOut(availability, value)
        break
      case 'nextAvailable':
        changeAvailabilityNextAvailableService(availability, value)
        break
      case 'mustCall':
        changeAvailabilityMustCall(availability, value)
        break
      default:
    }
  }
  return (
    <Fragment>
      <thead>
        <tr className='availability__table-header_title-row'>
          <th></th>
          <th className='availability__table-header_title-row__must-call-header'>
            <span>
              Must Call for Availability
              <Tooltip
                className='bork-bork-bork'
                name='Must Call Details'
                trigger={(<FontAwesomeIcon icon='question-circle' className='availability__table-header_title-row__must-call-icon'/>)}
                position='top-right'>
                <div className='availability__table-header_title-row__tooltip-content'>
                  <div className='availability__table-header_title-row__tooltip-content_details'>
                    Marking a size or service as must call signals that a rep has to call to find out when your next available inventory or service is.
                    This feature is primarily for when you are low or out of inventory.
                  </div>
                  <div className='availability__table-header_title-row__tooltip-content_details-note'>
                    <span className='availability__table-header_title-row__tooltip-content_details-note_text-warning'>
                      Note:
                    </span>
                    <span>
                      Having this feature set to must call might result in fewer orders so only use it when necessary.
                    </span>
                  </div>
                </div>
              </Tooltip>
            </span>
          </th>
          {columnTitles.map((title, index) => {
            return (
              <th key={index}>
                {title}
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        <tr className='availability__table-header_title-row__apply-all'>
          <td> Apply All </td>
          <td>
            <CheckboxWrapper
              size={'small'}
              checked = {applyAllHeaders.mustCall}
              loading = {applyAllLoading.mustCall}
              labelName = 'applyAllMustCall'
              labelText = 'Apply All Must Call'
              onChange = {() => applyAll(!applyAllHeaders.mustCall, 'mustCall',
                GRAPHQL_AVAILABILITY_DELIVERY_DAY.MUST_CALL, 'mustCall')}
            />
          </td>
          <td>
            <CheckboxWrapper
              size = {'small'}
              checked = {applyAllHeaders.sameDayActive}
              loading = {applyAllLoading.sameDayActive}
              labelName = 'applyAllSameDayActive'
              labelText = 'Apply All Same Day Active'
              onChange = {() => applyAll(!applyAllHeaders.sameDayActive, 'active',
                GRAPHQL_AVAILABILITY_DELIVERY_DAY.SAME_DAY, 'sameDayActive')}
            />
          </td>
          <td>
            <CheckboxWrapper
              size={'small'}
              checked = {applyAllHeaders.nextDayActive}
              loading = {applyAllLoading.nextDayActive}
              labelName = 'applyAllNextDayActive'
              labelText = 'Apply All Next Day Active'
              onChange = {() => applyAll(!applyAllHeaders.nextDayActive, 'active',
                GRAPHQL_AVAILABILITY_DELIVERY_DAY.NEXT_DAY, 'nextDayActive')}
            />
          </td>
          <td className='availability__table-header_title-row_days-out'>
            <NumberWrapper
              value = {applyAllHeaders.daysOut}
              loading = {applyAllLoading.daysOut}
              labelName = 'applyAllDaysOut'
              labelText = 'Apply All Days Out'
              onChange = {value => applyAll(value > 1 ? value : null, 'daysOut', '', 'daysOut')}
              min = {2}
            />
          </td>
          <td>
            <CalendarWrapper
              value = {determineApplyAllNextAvailable()}
              minDate = {new Date()}
              loading = {applyAllLoading.nextAvailableService}
              labelName = 'applyAllNextDeliverableDate'
              labelText = 'Apply All Next Available Service'
              onChange = {value => applyAll(value === null ? null : dayjs(value).format('YYYY-MM-DD'), 'nextAvailable',
                '', 'nextAvailableService')}
            />
          </td>
          <td>
            <CheckboxWrapper
              size = {'small'}
              checked = {applyAllHeaders.saturdayActive}
              loading = {applyAllLoading.saturdayActive}
              labelName = 'applyAllSaturdayActive'
              labelText = 'Apply All Saturday Active'
              onChange={() => applyAll(!applyAllHeaders.saturdayActive,
                'active', GRAPHQL_AVAILABILITY_DELIVERY_DAY.SATURDAY, 'saturdayActive')}
            />
          </td>
          <td>
            <CheckboxWrapper
              size = {'small'}
              checked = {applyAllHeaders.sundayActive}
              loading = {applyAllLoading.sundayActive}
              labelName = 'applyAllSundayActive'
              labelText = 'Apply All Sunday Active'
              onChange={() => applyAll(!applyAllHeaders.sundayActive,
                'active', GRAPHQL_AVAILABILITY_DELIVERY_DAY.SUNDAY, 'sundayActive')}
            />
          </td>
        </tr>
      </tbody>
    </Fragment>
  )
}

AvailabilityTableHeaders.propTypes = {
  availabilities: PropTypes.object.isRequired,
  applyAllHeaders: PropTypes.object.isRequired,
  setApplyAllHeaders: PropTypes.func.isRequired,
  applyAllLoading: PropTypes.object.isRequired,
  setApplyAllLoading: PropTypes.func.isRequired,
  changeAvailabilityActiveState: PropTypes.func.isRequired,
  changeAvailabilityNextAvailableService: PropTypes.func.isRequired,
  changeAvailabilityDaysOut: PropTypes.func.isRequired,
  changeAvailabilityMustCall: PropTypes.func.isRequired
}
