import React from 'react'
import PropTypes from 'prop-types'
import jwtDecode from 'jwt-decode'
import classNames from 'classnames'
import { SiteHeader } from '../../components/site-header'
import './blank-canvas.scss'

const BlankCanvasLayout = function AppLayout ({
  children,
  pageName,
  centeredOnScreen = false,
  showHeader = false,
  token = ''
}) {
  let decodedCookieIDToken = {}
  if (token.length) {
    decodedCookieIDToken = { ...decodedCookieIDToken, ...jwtDecode(token) }
  }

  return (
    <div className={classNames('empty-canvas empty-canvas_theme_dl', {
      'empty-canvas_centered': centeredOnScreen
    })}>
      <main id={`${pageName}-page`}>
        {showHeader &&
        <SiteHeader
          isUserLoggedIn={Object.keys(decodedCookieIDToken).length > 0}
          userID={decodedCookieIDToken.email}
          permissions={decodedCookieIDToken.permissions}
          isInternalUser={decodedCookieIDToken.isInternalUser}/>}
        {children}
      </main>
      <footer className='empty-canvas__footer empty-canvas__footer_theme_dl'>
        <p>&copy; {new Date().getFullYear()}, Dispatcher.com LLC</p>
      </footer>
    </div>
  )
}

BlankCanvasLayout.propTypes = {
  pageName: PropTypes.string.isRequired,
  centeredOnScreen: PropTypes.bool,
  showHeader: PropTypes.bool,
  IDToken: PropTypes.object
}

export default BlankCanvasLayout
