import { useQuery } from '@apollo/client'
import { ErrorPage } from '../error-page'
import { GET_ALL_AVAILABILITY } from '../../graphql/queries'
import { AvailabilityTable } from './table'
import './availability.scss'
import Spinner from 'react-spinkit'

export function Availability () {
  const { data: { haulerAvailabilities } = {}, loading, error } = useQuery(GET_ALL_AVAILABILITY)

  function sortBySizes () {
    return haulerAvailabilities.newDeliveries
      .sort((a, b) => {
        const aTitle = Number(a.size.name.split(' ')[0])
        const bTitle = Number(b.size.name.split(' ')[0])
        return aTitle - bTitle
      })
      .map(datum => ({
        ...datum,
        title: datum.size.name
      }))
  }

  function formattedAvailabilities () {
    return {
      newDeliveries: sortBySizes(),
      emptyReturn: [{ ...haulerAvailabilities.emptyReturn, title: 'Empty & Return' }],
      pickUp: [{ ...haulerAvailabilities.pickUp, title: 'Pick Up' }]
    }
  }

  function renderApp () {
    if (error) {
      return (
        <ErrorPage>
          <p className='availability__error-text'>
            Sorry, there was an error loading your availability.
          </p>
        </ErrorPage>
      )
    }

    if (loading) {
      return <Spinner name='circle' className='availability__spinner' fadeIn='none' />
    }

    return (
      <AvailabilityTable
        availabilities={formattedAvailabilities()}
      />
    )
  }

  return (
    <section className="availability availability__wrapper">
      <h2 className='availability availability__header'>Availability</h2>
      <div className='availability availability__app'>
        {renderApp()}
      </div>
    </section>
  )
}
