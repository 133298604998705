import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import './mobile-nav.scss'
export function MobileNav ({ setActiveLink, activeLink, newRequestIndicator, className }) {
  return (
    <div className='mobile-nav'>
      <ul className='mobile-nav__link-wrapper'>
        <li className='mobile-nav__link-wrapper__link-item'>
          <button
            className={classNames('mobile-nav__link-wrapper__link', {
              'mobile-nav__link-wrapper__link_active': activeLink === 'newRequests'
            })}
            onClick={() => setActiveLink('newRequests')}>
              New Requests
          </button>
        </li>
        <li className='mobile-nav__link-wrapper__link-item'>
          <button
            className={classNames('mobile-nav__link-wrapper__link', {
              'mobile-nav__link-wrapper__link_active': activeLink === 'scheduledJobs'
            })}
            onClick={() => setActiveLink('scheduledJobs')}>
            Scheduled Jobs
          </button>
        </li>
        <li className='mobile-nav__link-wrapper__link-item'>
          <button
            className={classNames('mobile-nav__link-wrapper__link', {
              'mobile-nav__link-wrapper__link_active': activeLink === 'availability'
            })}
            onClick={() => setActiveLink('availability')}>
            Availability
          </button>
        </li>
      </ul>
    </div>
  )
}

MobileNav.propTypes = {
  setActiveLink: PropTypes.func,
  activeLink: PropTypes.string,
  newRequestIndicator: PropTypes.number
}
