import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import Image from '../../components/image'
import { ErrorPage } from '../../components/error-page'
import { Loader } from '../../components/loader'
import { SUPPORT_CONTACTS } from '../../utils/constants'
import UserCreationForm from '../../components/user-creation-form'
import { NEW_USER_VERIFICATION } from './graphql'
import './user-creation-by-invitation.scss'

const UserCreationByInvitation = function UserCreationByInvitation (props) {
  const applicationID = process.env.REACT_APP_ACCOUNT_APPLICATION_ID
  const [headerMessage, setHeaderMessage] = useState('Welcome to Dispatcher Lite')
  const [secondaryHeaderMessage, setSecondaryHeaderMessage] = useState('Create your password to get started')
  const {
    data: { verifyInvitation } = {}, loading, error
  } = useQuery(NEW_USER_VERIFICATION, {
    variables: {
      uuid: props.uuid,
      invitationID: applicationID
    },
    fetchPolicy: 'no-cache'
  })

  if (loading) {
    return (
      <div className='new-requests__loader'>
        <Loader isFetching={true}/>
      </div>
    )
  }

  if (error || !verifyInvitation) {
    return (
      <ErrorPage>
        <p className='user-creation-form__error-text'>
          Sorry, there was an error with your invitation. Please contact <a href="mailto:{SUPPORT_CONTACTS.email}">{SUPPORT_CONTACTS.email}</a> for assistance.
        </p>
      </ErrorPage>
    )
  }

  return (
    <section className='user-creation'>
      <header className='user-creation__header'>
        <Image className='user-creation__logo' src='Dispatcher-Lite-Primary-Vertical.svg' alt='Dispatcher Lite logo'/>
        <h1 className='user-creation__header-text'>
          {headerMessage}
        </h1>
        <p className='user-creation__header-text user-creation__header-text_secondary'>
          {secondaryHeaderMessage}
        </p>
      </header>
      <UserCreationForm
        uuid={props.uuid}
        preEnteredEmail={verifyInvitation}
        applicationID={applicationID}
        setHeaderMessage={setHeaderMessage}
        setSecondaryHeaderMessage={setSecondaryHeaderMessage}
      />
    </section>
  )
}

export default UserCreationByInvitation
