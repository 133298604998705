import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCheckSquare,
  faEdit,
  faCheck,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faSlidersH,
  faTimesCircle,
  faCheckCircle,
  faUser,
  faExclamationCircle,
  faExternalLinkAlt,
  faStar,
  faFlag as flagSolid,
  faThLarge,
  faPlusCircle,
  faQuestionCircle,
  faCircle,
  faSave,
  faPlus,
  faMinus,
  faRedo,
  faUndo,
  faDownload,
  faPrint,
  faPhoneAlt,
  faPhoneSlash,
  faEye,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons'
import {
  faTruckContainer,
  faCreditCardFront,
  faAnalytics,
  faUsdCircle,
  faCloudUpload,
  faCloudUploadAlt,
  faMapMarkerAlt as faMapMarkerAltSolid,
  faSyncAlt,
  faLevelUpAlt,
  faInfoCircle,
  faSortDown,
  faSortUp,
  faSignOut
} from '@fortawesome/pro-solid-svg-icons'
import {
  faMapMarkerAlt,
  faEnvelope,
  faArrowLeft,
  faBars,
  faTimes,
  faSlidersV,
  faFlag,
  faEllipsisH,
  faExternalLinkSquare,
  faSearch,
  faQuestionCircle as faQuestionCircleOutline,
  faVideo
} from '@fortawesome/pro-regular-svg-icons'

export function addIcons () {
  library.add(
    faCheckSquare,
    faEdit,
    faCheck,
    faChevronDown,
    faChevronUp,
    faChevronLeft,
    faChevronRight,
    faSlidersH,
    flagSolid,
    faTimesCircle,
    faCheckCircle,
    faUser,
    faExclamationCircle,
    faExternalLinkAlt,
    faStar,
    faThLarge,
    faPlusCircle,
    faQuestionCircle,
    faQuestionCircleOutline,
    faCircle,
    faEnvelope,
    faSave,
    faPlus,
    faMinus,
    faRedo,
    faUndo,
    faDownload,
    faTruckContainer,
    faCreditCardFront,
    faAnalytics,
    faUsdCircle,
    faCloudUpload,
    faCloudUploadAlt,
    faMapMarkerAltSolid,
    faMapMarkerAlt,
    faSyncAlt,
    faLevelUpAlt,
    faBars,
    faTimes,
    faSlidersV,
    faFlag,
    faEllipsisH,
    faExternalLinkSquare,
    faSearch,
    faInfoCircle,
    faPhoneAlt,
    faPhoneSlash,
    faSortDown,
    faSignOut,
    faSortUp,
    faPrint,
    faEye,
    faEyeSlash,
    faVideo,
    faArrowLeft
  )
}
