import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation } from '@apollo/client'
import classNames from 'classnames'
import { ORDER_TYPES, HAULER_ACTIONS } from './constants'
import { MARK_SERVICE_AS_COMPLETE } from '../../graphql/mutations'
import { JobDetailsModal } from '../job-details-modal/'
import { ScheduledJobsDetailsButtons } from './details-button'
import { Alert } from '../alert/'
// import { ETAPickerPopover } from '../eta-picker/'
// import { DropdownSelect } from '../dropdown-select/'
// import { ETA_PICKER_VALUES } from '../eta-picker/constants'

function getCompletionStepAtStart (haulerActions, completeAll) {
  if (haulerActions.indexOf(HAULER_ACTIONS.serviceComplete) === -1 || completeAll) {
    return 2
  }
  return 0
}
export function ScheduledJobsTableRow ({
  service,
  canAction,
  completeAll,
  index,
  disabledRows,
  setDisabledRows,
  requestRefetch,
  markAllEditing
}) {
  const serviceProduct = service.products[0]
  const isPickup = serviceProduct.serviceType === 'PICK_UP'
  const dateToUse = isPickup ? serviceProduct.pickupDate : serviceProduct.deliveryDate
  const [markServiceAsComplete] = useMutation(MARK_SERVICE_AS_COMPLETE)
  const [completionStep, setCompletionStep] = useState(0)
  const [detailsOpen, setDetailsOpen] = useState(false)
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  // const [etaTime, setETATime] = useState('')
  const disabledRow = canAction && disabledRows.isEditing && disabledRows.editingIndex !== index

  useEffect(() => {
    setCompletionStep(getCompletionStepAtStart(serviceProduct.haulerActions, completeAll))
  }, [serviceProduct.haulerActions, completeAll])

  if (completionStep !== 2 && completeAll) {
    markServiceAsComplete({ variables: { service: serviceProduct.caseProductID } })
      .then(() => {
        setCompletionStep(2)
      })
      .catch(err => {
        let errorText = ''
        if (err.message.includes('Workflow Error')) {
          errorText = `This service has a pending change. Please <a href="${window.location}">refresh</a> for the latest status and reach out to support@cleanupcontractors.com.`
        }
        setErrorMessage(errorText)
        setShowError(true)
      })
  }

  function setAsComplete () {
    if (completionStep === 2) {
      return
    }

    if (completionStep === 1) {
      return markServiceAsComplete({ variables: { service: serviceProduct.caseProductID } })
        .then(() => {
          setCompletionStep(2)
          setDisabledRows({ isEditing: false, editingIndex: -1 })
        })
        .catch((err) => {
          let errorText = ''
          if (err.message.includes('Workflow Error')) {
            errorText = `This service has a pending change. Please <a href="${window.location}">refresh</a> for the latest status and reach out to support@cleanupcontractors.com.`
          }
          setErrorMessage(errorText)
          setShowError(true)
        })
    }

    if (completionStep === 0) {
      setDisabledRows({ isEditing: true, editingIndex: index })
    }
    return setCompletionStep(completionStep + 1)
  }

  function cancelComplete () {
    setCompletionStep(0)
    setDisabledRows({ isEditing: false, editingIndex: -1 })
  }

  function canActionByStep () {
    if (completionStep === 0) {
      return (
        <td className='scheduled-jobs__mark-service-complete scheduled-jobs__table__row_button'>
          <button
            onClick={setAsComplete}
            disabled = {disabledRow || markAllEditing}
            className='button__submit button__submit--primary_green'>
            Mark Complete
          </button>
        </td>
      )
    }

    if (completionStep === 1) {
      return (
        <React.Fragment>
          <td className='scheduled-jobs__table__row_type scheduled-jobs_mobile'>{ORDER_TYPES[serviceProduct.serviceType]} -</td>
          <td className='scheduled-jobs__table__row_size scheduled-jobs_mobile'>{serviceProduct.size} Yard</td>
          <td colSpan='3' className='scheduled-jobs__verify-step'>
            {!showError &&
            <div className='scheduled-jobs__verify-step-wrapper'>
              <p className='scheduled-jobs__verify-text'>Please click again to verify this job is complete</p>
              <button
                onClick={() => cancelComplete()}
                className='button__submit button__submit--primary_white scheduled-jobs__verify-step-cancel'
              >
                  Cancel
              </button>
              <button
                onClick={setAsComplete}
                className='button__submit button__submit--primary_green scheduled_jobs__verify-step-verify'
              >
                  Verify Completion
              </button>
            </div>}
            <Alert
              alertType = 'error'
              isOpen = {showError}
              errorMessage={errorMessage}
              setIsOpen = {setShowError}/>
          </td>
        </React.Fragment>
      )
    }

    if (completionStep === 2) {
      return (
        <td className='scheduled-jobs__mark-service-complete scheduled-jobs__table__row_button'>
          <button disabled className= 'button__submit button__submit--primary_mint'>
            <FontAwesomeIcon
              icon={'check'}
              className='scheduled-jobs__verify-checkmark'
            />
            <span>Completed</span>
          </button>
        </td>
      )
    }
  }

  function onClose () {
    if (canAction) {
      requestRefetch().then((res) => {
        const updatedService = res.data.haulerScheduledServicesByDate.find(job => job.products[0].caseProductID === serviceProduct.caseProductID)
        setCompletionStep(getCompletionStepAtStart(updatedService.products[0].haulerActions, completeAll))
      })
    }
  }

  return (
    <React.Fragment>
      <JobDetailsModal
        isOpen = {detailsOpen}
        setIsOpen = {setDetailsOpen}
        title='Scheduled Job Details'
        service = {service}
        requestDate = {dateToUse}
        onCloseEvent = {onClose}
        buttonComponent = {
          <ScheduledJobsDetailsButtons
            completionStep = {completionStep}
            canAction = {canAction}
            caseID = {serviceProduct.caseProductID}
          />
        }
        etaComponent = {
          /* <DropdownSelect
            selected = {etaTime}
            disabled = {completionStep >= 2}
            selectOptions = {ETA_PICKER_VALUES}
            setSelected = {setETATime}
            className = 'w-full'
          /> */
          false
        }
      />
      <tr className={classNames(
        {
          'scheduled-jobs__service-row_disabled': disabledRow || !canAction,
          'scheduled-jobs__service-row_error': showError
        })}>
        <td>
          <FontAwesomeIcon
            role='button'
            aria-label='Scheduled Jobs Details Modal'
            icon = {'info-circle'}
            className = {classNames('scheduled-jobs__info-icon', {
              'scheduled-jobs__info-icon_disabled': disabledRow
            })}
            onClick={() => {
              if (disabledRow) return
              setDetailsOpen(true)
            }}
          />
        </td>
        <td className='scheduled-jobs__table__row_address'>
          {serviceProduct.address.street}, {serviceProduct.address.city}, {serviceProduct.address.state} {serviceProduct.address.postalCode}
        </td>
        { completionStep !== 1
          ? (
            <React.Fragment>
              <td className='scheduled-jobs__table__row_type'>
                {ORDER_TYPES[serviceProduct.serviceType]}
                <span className='scheduled-jobs_mobile'> - </span>
              </td>
              <td className='scheduled-jobs__table__row_size'>{serviceProduct.size} Yard</td>
              {/* <td className='font-lato-bold text-center'>AM</td> */}
              {/* <td className='text-center'>
                <div className='relative'>
                  { !canAction || disabledRow || completionStep >= 2 ? (
                    <div className='cursor-not-allowed'>
                      <FontAwesomeIcon
                        icon={['far', 'clock']}
                        className='fa-lg'
                      />
                      <div>Set</div>
                    </div>
                  )
                    : (
                      <ETAPickerPopover
                        etaTime={etaTime}
                        setETATime = {setETATime}
                      />
                    )
                  }
                </div>
              </td> */}
            </React.Fragment>
          ) : null }
        { canAction
          ? (
            canActionByStep()
          )
          : (
            <td className='schedule-jobs__cancelled-text scheduled-jobs__table__row_button'>
              { service.status.toUpperCase() === 'VOIDED' ? 'VOIDED' : 'CANCELLED' }
            </td>
          )
        }
      </tr>
    </React.Fragment>
  )
}

ScheduledJobsTableRow.propTypes = {
  canAction: PropTypes.bool.isRequired,
  completeAll: PropTypes.bool.isRequired,
  service: PropTypes.shape({
    status: PropTypes.string.isRequired,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        serviceType: PropTypes.string.isRequired,
        caseProductID: PropTypes.string.isRequired,
        size: PropTypes.number.isRequired,
        debris: PropTypes.string.isRequired,
        productStatus: PropTypes.string.isRequired,
        address: PropTypes.shape({
          street: PropTypes.string.isRequired,
          city: PropTypes.string.isRequired,
          postalCode: PropTypes.string.isRequired
        }),
        haulerActions: PropTypes.arrayOf(PropTypes.string).isRequired
      }).isRequired
    ).isRequired
  }).isRequired,
  disabledRows: PropTypes.object,
  setDisabledRows: PropTypes.func,
  requestRefetch: PropTypes.func,
  markAllEditing: PropTypes.bool
}
