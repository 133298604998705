import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation } from '@apollo/client'
import { INVITE_USER } from './graphql'
import { Alert } from '../alert'
import { Loader } from '../loader'
import { debounce } from '../../utils/'
import { emailValidation } from '../../utils/validation'
import './invite-user-form.scss'

const InviteUserForm = function InviteUserForm ({
  setPageStep,
  setParentErrorText,
  userEmails,
  setUserEmails
}) {
  const [formErrorText, setErrorText] = useState('')
  const [sfID, setSFID] = useState('')
  const [showError, setShowError] = useState(false)
  const [emailErrors, setFormErrors] = useState([])

  const [
    inviteUser, { loading: activeLoading }
  ] = useMutation(INVITE_USER)

  function handleEmailInputChange (e) {
    const emails = [...userEmails]
    const emailErr = [...emailErrors]
    const enteredValue = e.target.value
    const inputName = e.target.name
    const [, step] = inputName.split('-')

    emails[step] = enteredValue
    setUserEmails(emails)

    if (emailValidation(enteredValue)) {
      setFormErrors(emailErr.filter(v => v !== inputName))
      setErrorText('')
      setShowError(false)
    } else {
      emailErr.push(inputName)
      setFormErrors(emailErr)
      setShowError(true)
      setErrorText('email')
    }
  }

  /**
   * determine if the form can be submitted (used by submit button to show disabled or not)
   * @returns Boolean
   */
  function canSubmit () {
    const [firstEmail] = userEmails

    return !(emailErrors.length > 0 || !firstEmail || sfID === '' || activeLoading)
  }

  /**
   * determine if a new email input can be put on the screen and set it up to do so
   * @param {Object} e event object
   */
  function handleAddEmailBtn (e) {
    e.preventDefault()
    const emails = userEmails.slice()
    emails.push('')
    setUserEmails(emails)
  }

  /**
   * Remove the current email input from the screen and from any errors
   * @param {Object} e event object
   */
  function handleRemoveEmail (e) {
    const emails = [...userEmails]
    const emailErr = [...emailErrors]
    const [,, step] = e.currentTarget.name.split('-')

    emails.splice(step, 1)
    setFormErrors(emailErr.filter(v => v !== `username-${step}`))
    setUserEmails(emails)
    setShowError(false)
  }

  function formSubmission (e) {
    e.preventDefault()

    const mutationObj = {
      userNames: userEmails,
      application: 'DISPATCHER_LITE',
      userDataIDs: sfID.replace(/\s+/g, '').split(','),
      sendInvitationURL: `${window.location.origin}/user-creation/`
    }

    return inviteUser({
      variables: {
        invitationInput: mutationObj
      }
    })
      .then(() => {
        setPageStep(1)
      })
      .catch(err => {
        let errText = 'General Error Occurred.'
        if (err.message.includes('Following accounts were not found')) {
          errText = `The account was not able to be created. ${err.message.replace('GraphQL error: Error: Following', 'The following Salesforce ')}`
        }

        setParentErrorText(errText)
        setPageStep(-1)
      })
  }

  return (
    <form
      onSubmit={formSubmission}
      className='invite-user-form'
      name='user-creation-submission'
    >
      {
        formErrorText !== '' &&
        <Alert
          alertType = 'error'
          isOpen = {showError}
          setIsOpen = {setShowError}
          errorMessage='Please provide a valid email address.'
        />
      }
      { userEmails.map((email, i) => {
        return (
          <label
            key={`email-${i}`}
            className={classNames({
              'invite-user-form__input--secondary': i > 0
            })}
          >
          Email Address
            <input
              name={`username-${i}`}
              defaultValue={email}
              type='email'
              className={classNames({
                'invite-user-form__input--error': emailErrors.includes(`username-${i}`)
              })}
              required={true}
              onKeyUp={debounce(handleEmailInputChange, 1000)}
            />
            { i > 0 &&
            <button
              aria-label='remove email address'
              type='button'
              className='button--action'
              name={`remove-email-${i}`}
              onClick={handleRemoveEmail}
            >
              <FontAwesomeIcon
                icon = {'plus'}
                className = 'invite-user-form--icon_remove_email' />
            </button>
            }
          </label>
        )
      }) }

      <button
        type='button'
        className='button--action invite-user-form__add-btn'
        onClick={handleAddEmailBtn}
        disabled={(userEmails[userEmails.length - 1] === '' || emailErrors.length !== 0)}
      >
        + Add Another Email
      </button>

      <label>
        Salesforce ID
        <input
          name='salesforce-id'
          type='input'
          onChange={e => setSFID(e.target.value)}
          className={classNames({
            'invite-user-form__input--error': emailErrors.includes('salesforce-id')
          })}
          required={true}
        />
      </label>
      <button
        className='invite-user-form__submit-btn button__submit button__submit--primary_green'
        type='submit'
        disabled={!canSubmit()}
      >
        { activeLoading
          ? <Loader isFetching={true}/>
          : 'CREATE & SEND'
        }
      </button>
    </form>
  )
}

InviteUserForm.propTypes = {
  setPageStep: PropTypes.func.isRequired,
  setParentErrorText: PropTypes.func.isRequired,
  userEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
  setUserEmails: PropTypes.func.isRequired
}

export default InviteUserForm
