import React from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-spinkit'
import './checkbox.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

export function CheckboxWrapper ({
  checked,
  disabled,
  loading,
  onChange,
  size,
  labelName,
  customCheckbox,
  labelText
}) {
  return (loading
    ? <Spinner name='circle'fadeIn='none' className='shared-inputs__spinner shared-inputs__spinner_centered'/>
    : (
      <label htmlFor={labelName} className={classNames('shared-inputs__checkbox', {
        'shared-inputs__checkbox_checked': checked && !customCheckbox,
        [customCheckbox ? customCheckbox.checkedLabelClasses : '']: checked && customCheckbox,
        [customCheckbox ? customCheckbox.uncheckedLabelClasses : '']: !checked && customCheckbox,
        'shared-inputs__checkbox_small': size === 'small',
        'shared-inputs__checkbox_large': size === 'large'
      })}>
        <input
          aria-label={labelText}
          id={labelName}
          type="checkbox"
          className="hidden"
          readOnly
          disabled={disabled}
          onChange={onChange}
        />
        <div className='shared-inputs__checkbox-wrapper'>
          {checked && !customCheckbox && (
            <FontAwesomeIcon
              icon={'check'}
              className='shared-inputs__checkbox-icon'/>
          )}
          {checked && customCheckbox && (
            <FontAwesomeIcon
              icon={customCheckbox.checkedIcon}
              className={customCheckbox.checkedIconClasses}/>
          )}
          {!checked && customCheckbox && (
            <FontAwesomeIcon
              icon={customCheckbox.uncheckedIcon}
              className={customCheckbox.uncheckedIconClasses}/>
          )}
        </div>
      </label>
    ))
}

CheckboxWrapper.defaultProps = {
  secondary: false,
  checked: false,
  containerProps: {},
  disabled: false,
  size: 'large',
  customCheckbox: null
}

CheckboxWrapper.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['small', 'large']),
  customCheckbox: PropTypes.object,
  labelText: PropTypes.string.isRequired
}
