import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Loader } from '../loader'

export default function ScheduledJobsMarkCompleteButton ({ markAll, allComplete, disabledBtn, completionStep, setCompletionStep }) {
  function completionStepper () {
    if (completionStep === 1 && markAll) {
      markAll(true)
    }

    if (completionStep < 2) {
      return setCompletionStep(completionStep + 1)
    }

    if (markAll) {
      return markAll(true)
    }
  }

  if (allComplete) {
    return <td colSpan='5' className='scheduled-jobs__mark-complete-cell'></td>
  }

  if (completionStep === 0 && !allComplete) {
    return (
      <td colSpan='5' className='scheduled-jobs__mark-complete-cell'>
        <div className='scheduled-jobs__mark-complete-wrapper'>
          <button
            onClick={completionStepper}
            disabled={disabledBtn}
            className='button__submit button__submit--primary_green'>
              Mark All Complete
          </button>
        </div>
      </td>
    )
  }

  if (completionStep === 1 && !allComplete) {
    return (
      <td colSpan='5' className='scheduled-jobs__mark-complete-cell'>
        <div className='scheduled-jobs__mark-complete-wrapper scheduled-jobs__mark-complete-wrapper_verify'>
          <p className='scheduled-jobs__verify-text_mark-all'>
            Please click again to verify that all jobs listed above are complete.
          </p>
          <button
            onClick={() => setCompletionStep(0)}
            className='button__submit button__submit--primary_white scheduled-jobs__verify-step-cancel_mark-all'>
              Cancel
          </button>
          <button
            onClick={completionStepper}
            className='button__submit button__submit--primary_green'>
                Verify Completion
          </button>
        </div>
      </td>
    )
  }

  return (
    <td colSpan='5'>
      <div className='scheduled-jobs__mark-service-complete scheduled-jobs__mark-service-complete_all'>
        <button disabled className= 'button__submit button__submit--primary_mint'>
          <FontAwesomeIcon
            icon={'check'}
            className='scheduled-jobs__verify-checkmark'
          />
          <span>Completed</span>
        </button>
      </div>
    </td>
  )
}

ScheduledJobsMarkCompleteButton.propTypes = {
  markAll: PropTypes.func,
  allComplete: PropTypes.bool,
  disabledBtn: PropTypes.bool,
  setDisabled: PropTypes.func
}
