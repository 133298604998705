import { gql } from '@apollo/client'

export const INVITE_USER = gql`
  mutation creationInvitation($invitationInput: CreateInvitationInput!) {
    createInvitation(input: $invitationInput) {
      invitee
      ctaID
    }
  }
`
