import React from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-spinkit'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './date-picker.scss'
import '../inputs.scss'

export function CalendarWrapper ({
  value = null,
  disabled = false,
  loading,
  onChange,
  labelName,
  minDate,
  labelText
}) {
  return (loading
    ? <Spinner name='circle' className='shared-inputs__spinner shared-inputs__spinner_centered' fadeIn='none' />
    : (
      <React.Fragment>
        <label className='shared-inputs__hidden-label' htmlFor={labelName}>{labelText}</label>
        <DatePicker
          id={labelName}
          className='shared-inputs__calendar'
          minDate={minDate}
          popperClassName='shared-inputs__calendar-popper'
          disabled={disabled}
          selected={value}
          onChange={onChange}
          dateFormat='MM/dd/yyyy'
          popperPlacement='left'
          popperModifiers={{
            preventOverflow: {
              enabled: true
            }
          }}
        />
      </React.Fragment>
    ))
}

CalendarWrapper.propTypes = {
  value: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  labelName: PropTypes.string,
  minDate: PropTypes.instanceOf(Date).isRequired,
  labelText: PropTypes.string.isRequired
}
