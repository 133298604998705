import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@apollo/client'
import classNames from 'classnames'
import format from 'date-fns/format'
import startOfDay from 'date-fns/startOfDay'
import { Loader } from '../loader/'
import { ErrorPage } from '../error-page'
import { HAULER_SCHEDULED_SERVICES_BY_DATE } from '../../graphql/queries'
import { ScheduledJobsTable } from './table'
import { ScheduledJobsDate } from './dates'
import './scheduled-jobs.scss'

export function ScheduledJobs (props) {
  const { haulerID, halfHeight, refreshEvent } = props
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [date, setDate] = useState(format(startOfDay(new Date()), 'y-MM-dd'))
  const [scheduleRefresh, setScheduleRefresh] = useState(0)
  const {
    data: { haulerScheduledServicesByDate } = {}, error: scheduledJobsError, loading: scheduledJobsLoading, refetch
  } = useQuery(HAULER_SCHEDULED_SERVICES_BY_DATE, {
    variables: {
      haulerID,
      date
    },
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (scheduleRefresh !== refreshEvent) {
      setScheduleRefresh(refreshEvent)
      refetch()
        .catch(() => console.error('Error refetching scheduled jobs'))
    }
  }, [scheduleRefresh, refreshEvent, refetch])

  function refetchScheduledJobs () {
    return refetch()
      .catch(() => console.error('Error refetching scheduled jobs'))
      .finally(() => { setIsRefreshing(false) })
  }

  function renderScheduledJobs () {
    if (scheduledJobsLoading) {
      return (
        <div className='scheduled-jobs__loader'>
          <Loader isFetching={true}/>
        </div>
      )
    }

    if (scheduledJobsError) {
      return (
        <ErrorPage>
          <p className='scheduled-jobs__error-text'>
            Sorry, there was an error retrieving your scheduled jobs.
          </p>
        </ErrorPage>
      )
    }

    if (!haulerScheduledServicesByDate.length) {
      return (
        <p className='scheduled-jobs__no-jobs'>No scheduled jobs for this date.</p>
      )
    }

    return (
      <ScheduledJobsTable requestRefetch = {refetch} haulerScheduledServicesByDate={haulerScheduledServicesByDate}/>
    )
  }
  return (
    <section className={classNames('scheduled-jobs__section', {
      'scheduled-jobs__section_half-height': halfHeight
    })}>
      <div className='scheduled-jobs__header'>
        <h2 className='scheduled-jobs__title'>
          Scheduled Jobs
          <button
            disabled={scheduledJobsLoading}
            aria-label='Refresh Scheduled Jobs'
            className='scheduled-jobs__refresh-icon'
            onClick={() => {
              setIsRefreshing(true)
              refetchScheduledJobs()
            }}
          >
            {
              isRefreshing
                ? <Loader isFetching={true} className='scheduled-jobs__refresh-loader'/>
                : <FontAwesomeIcon
                  icon={'redo'}
                  className=''
                />
            }
          </button>
        </h2>
        <ScheduledJobsDate
          date={date}
          setDate={setDate}
          isLoading={scheduledJobsLoading}
        />
      </div>
      <div className='scheduled-jobs__app'>
        {renderScheduledJobs()}
      </div>
    </section>
  )
}

ScheduledJobs.propTypes = {
  haulerID: PropTypes.string.isRequired,
  halfHeight: PropTypes.bool,
  refreshEvent: PropTypes.number.isRequired
}
