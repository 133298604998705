import React from 'react'
import PropTypes from 'prop-types'
import './mobile-menu.scss'

export function MobileLogout ({
  logout,
  toggleLogoutModal
}) {
  return (
    <div className='mobile-menu-container__logout-modal'>
      <h2 className='mobile-menu-container__logout-modal__title'>Log Out</h2>
      <div className='mobile-menu-container__logout-modal__confirmation-msg'>
        Are you sure you want to log out?
      </div>
      <span className='mobile-menu-container__logout-modal__buttons'>
        <button
          className='button__submit button__submit--primary_white'
          onClick={() => toggleLogoutModal(false)}>
            No
        </button>
        <button
          className='button__submit button__submit--primary_green'
          onClick={logout}>
            Yes
        </button>
      </span>
    </div>
  )
}

MobileLogout.propTypes = {
  logout: PropTypes.func.isRequired,
  toggleLogoutModal: PropTypes.func.isRequired
}
