import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Spinner from 'react-spinkit'
import './number.scss'
import '../inputs.scss'
import { classNamePropTypes } from '../../../utils/classNamePropTypes'

export function NumberWrapper ({
  className = null,
  value = null,
  disabled = false,
  loading,
  onChange,
  min = 0,
  labelName,
  labelText
}) {
  return (loading
    ? <Spinner name='circle' className='shared-inputs__spinner shared-inputs__spinner_centered' fadeIn='none' />
    : (
      <React.Fragment>
        <label htmlFor={labelName}/>
        <input
          id={labelName}
          aria-label={labelText}
          className={classNames('shared-inputs__number', className)}
          disabled={disabled}
          value={value || ''}
          onChange={event => onChange(Number(event.currentTarget.value))}
          type="number"
          min={min}
        />
      </React.Fragment>
    ))
}

NumberWrapper.propTypes = {
  className: classNamePropTypes,
  value: PropTypes.number,
  disabled: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  labelName: PropTypes.string,
  labelText: PropTypes.string.isRequired
}
