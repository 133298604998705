import React from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { ORDER_TYPES } from './constants'
import Modal from '../modal/'
import './job-details-modal.scss'

export function JobDetailsModal ({
  isOpen,
  setIsOpen,
  title,
  service,
  requestDate,
  buttonComponent,
  etaComponent,
  onCloseEvent,
  formattedProducts
}) {
  const product = service.products[0]
  const address = `${product.address.street}, ${product.address.city}, ${product.address.state}, ${product.address.postalCode}`
  const mapURL = `https://maps.googleapis.com/maps/api/staticmap?markers=size:tiny|${encodeURI(address)}&zoom=14&size=274x189&scale=2&maptype=roadmap&key=${process.env.REACT_APP_GOOGLE_API_KEY}`

  function removeStyleTags (attr) {
    if (attr) {
      return attr.replaceAll(/style="[^"]*"/gi, '')
    }
    return null
  }

  return (
    <Modal
      isOpen = {isOpen}
      setIsOpen = {setIsOpen}
      title = {title}
      onCloseEvent = {onCloseEvent}
      canPrint = {false}
    >
      <div className='details-container'>
        <div className='details-container__details'>
          <div className='details-container__details__fields'>
            <div className='details-container__details__fields__title'>
              Service Date
            </div>
            <div className='details-container__details__fields__title'>
              Order Number
            </div>
            <div className='details-container__details__fields__details'>
              {(requestDate != null && requestDate !== '') ? format(parseISO(requestDate), 'MM/dd/yy') : null}
            </div>
            <div className='details-container__details__fields__details'>
              {service.orderNumber}
            </div>
            <div className='details-container__details__fields__title_address'>
              Address
            </div>
            <div className='details-container__details__fields__details details-container__details__fields__address'>
              {address}
              <div className='details-container__map-link-mobile'>
                <a target='_blank' rel="noopener noreferrer" href={`https://maps.google.com/?q=${address}`}>Open In Google Maps</a>
              </div>
            </div>
            <div className='details-container__details__fields__title'>
              Order Type
            </div>
            <div className='details-container__details__fields__title'>
              { formattedProducts && service.products.length > 1 ? 'Products' : 'Product' }
            </div>
            <div className='details-container__details__fields__details'>
              {ORDER_TYPES[product.serviceType]}
            </div>
            <div className='details-container__details__fields__details'>
              { formattedProducts || `${product.size} Yard` }
            </div>
            <div className='details-container__details__fields__details_hidden'>
              <div className='details-container__details__fields__title'>
                Time Request
              </div>
              <div className='details-container__details__fields__title'>
                {etaComponent && <span>ETA</span>}
              </div>
              <div className='details-container__details__fields__details'>
              </div>
              <div className='details-container__details__fields__details details-container__details__fields__eta'>
                {etaComponent}
              </div>
            </div>
          </div>
          <div className='details-container__details__map-container'>
            <div className='details-container__details__map-container__map'>
              <img src={mapURL} alt='map' className='details-container__details__map-container__map__map-image'/>
              <div className='details-container__details__map-container__map__map-link'>
                <a target='_blank' rel="noopener noreferrer" href={`https://maps.google.com/?q=${address}`}>Open In Google Maps</a>
              </div>
            </div>
          </div>
        </div>
        <div className='details-container__details-secondary'>
          <div className='details-container__details-secondary__section_full'>
            <div className='details-container__details-secondary__section__title'>
              Placement Instructions
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: removeStyleTags(product.deliveryInstructions) }}/>
          </div>
          <div className='details-container__details-secondary__section_full'>
            <div className='details-container__details-secondary__section__title'>
              Special Requests
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: removeStyleTags(product.specialRequests) }}/>
          </div>
          <div className='details-container__details-secondary__section_half'>
            <div className='details-container__details-secondary__section__title'>
              Onsite Contact
            </div>
            <div className='details-container__details-secondary__section__title'>
              Contact Phone
            </div>
            <div className='details-container__details-secondary__section__details'>
              {service.onSiteContact.fullName}
            </div>
            <div className='details-container__details-secondary__section__details'>
              {service.onSiteContact.phone}
            </div>
          </div>
        </div>
        {buttonComponent}
      </div>
    </Modal>
  )
}

JobDetailsModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  requestDate: PropTypes.string.isRequired,
  service: PropTypes.shape({
    status: PropTypes.string.isRequired,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        serviceType: PropTypes.string.isRequired,
        caseProductID: PropTypes.string.isRequired,
        size: PropTypes.number.isRequired,
        debris: PropTypes.string.isRequired,
        productStatus: PropTypes.string.isRequired,
        address: PropTypes.shape({
          street: PropTypes.string.isRequired,
          city: PropTypes.string.isRequired,
          postalCode: PropTypes.string.isRequired
        }),
        haulerActions: PropTypes.arrayOf(PropTypes.string).isRequired
      }).isRequired
    ).isRequired
  }).isRequired,
  formattedProducts: PropTypes.string,
  onCloseEvent: PropTypes.func
}
